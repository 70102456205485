// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getBonds = createAsyncThunk('bond/getBonds', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/bonds`, {
      params: { page: params.page, itemsPage: params.itemsPerPage }
    });
    return {
      params,
      data: response.data,
      totalRecords: response.data.numberRecordsTotal,
      totalPages: response.data.numberOfPages,
      nextPage: response.data.nextPage,
      previousPage: response.data.previousPage 
    };
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});
export const getSpecificBond = createAsyncThunk('bond/getSpecificBond', async (ticker, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/bond/${ticker}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export const resetSpecificBond = createAsyncThunk('bond/resetSpecificBond', async params => {
  return {
    params,
    data: {},
  }
})

export const getTags = createAsyncThunk('tags/getTags', async params => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/markettags?page=1&itemsPage=10`, params)
  return {
    params,
    data: response.data.list,
  }
})

export const putBond = createAsyncThunk('bond/putBond', async params => {
  const response = await axios.put(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/bond`, params)
  return response
})

export const disableBond = createAsyncThunk('bond/disableBond', async ticker => {
  const response = await axios.delete(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/bond/${ticker}`)
  return response
})

export const bondSlice = createSlice({
  name: 'bonds',
  initialState: {
    items: [],
    totalPages: 1,
    currentPage: 1,
    bondParams: {},
    bond: {},
    tags: [],
    loadingBond: false,
    errorBond: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getBonds.pending, (state) => {
      state.loadingBond = true;
      state.errorBond = null;
    })
    .addCase(getBonds.fulfilled, (state, action) => {
      state.items = action.payload.data.list;
      state.totalRecords = action.payload.data.numberRecordsTotal;
      state.totalPages = action.payload.data.numberOfPages;
      state.currentPage = action.payload.params.page;
      state.bondParams = action.payload.params;
      state.loadingBond = false;
    })
    .addCase(getBonds.rejected, (state, action) => {
      state.loadingBond = false;
      state.errorBond = action.payload;
    })
    .addCase(getSpecificBond.pending, (state) => {
      state.loadingBond = true;
      state.errorBond = null;
    })
    .addCase(getSpecificBond.fulfilled, (state, action) => {
      state.bond = action.payload;
      state.loadingBond = false;
    })
    .addCase(getSpecificBond.rejected, (state, action) => {
      state.loadingBond = false;
      state.errorBond = action.payload;
    })
    .addCase(resetSpecificBond.pending, (state) => {
      state.loadingBond = true;
      state.errorBond = null;
    })
    .addCase(resetSpecificBond.fulfilled, (state) => {
      state.bond = {};
      state.loadingBond = false;
    })
    .addCase(resetSpecificBond.rejected, (state, action) => {
      state.loadingBond = false;
      state.errorBond = action.payload;
    })
    .addCase(getTags.pending, (state) => {
      state.loadingBond = true;
      state.errorBond = null;
    })
    .addCase(getTags.fulfilled, (state, action) => {
      state.tags = action.payload.data;
      state.loadingBond = false;
    })
    .addCase(getTags.rejected, (state, action) => {
      state.loadingBond = false;
      state.errorBond = action.payload;
    })
  }
})

export default bondSlice.reducer