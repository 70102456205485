// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// -- Get Conciliation Errors
export const getConciliationErrors = createAsyncThunk(
  'appFiles/getConciliationErrors',
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/errors-conciliation`
      )
      return response.data
    } catch (error) {
      console.error('Error fetching conciliation errors:', error)
      throw error.response?.data?.message || 'Error al obtener errores de conciliación'
    }
  }
)

// -- GET List Files
export const getFilesList = createAsyncThunk(
  'appFiles/getFilesList',
  async (fileType) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/list-files/${fileType}`
      )
      return {
        fileType,
        data: response.data
      }
    } catch (error) {
      console.error('Error fetching files:', error)
      throw error.response?.data?.message || 'Error al obtener la lista de archivos'
    }
  }
)

// -- Download File
export const downloadFile = createAsyncThunk(
  'appFiles/downloadFile',
  async ({ fileType, encrypted, fileName }) => {
    try {
      // Get the signed URL first
      const urlResponse = await axios.post(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/download_file`,
        {
          file_type: fileType,
          encrypted,
          file_name: fileName
        }
      )

      if (!urlResponse.data.url) {
        throw new Error('No se recibió URL de descarga')
      }

      // Download from the signed URL
      window.open(urlResponse.data.url, '_blank')

      return {
        fileType,
        success: true
      }
    } catch (error) {
      console.error('Error downloading file:', error)
      throw error.response?.data?.message || 'Error al descargar el archivo'
    }
  }
)

// -- Get Detail Conciliation
export const getDetailConciliation = createAsyncThunk(
  'appFiles/getDetailConciliation',
  async (date) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/detail-conciliation`,
        {
          params: { date }
        }
      )
      return response.data
    } catch (error) {
      console.error('Error fetching conciliation details:', error)
      throw error.response?.data?.message || 'Error al obtener los detalles de conciliación'
    }
  }
)

// -- Fix Conciliation
export const fixConciliation = createAsyncThunk(
  'appFiles/fixConciliation',
  async ({ id, detail = null }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/fix-conciliation`,
        {
          id,
          detail
        }
      )
      return response.data
    } catch (error) {
      console.error('Error fixing conciliation:', error)
      throw error.response?.data?.message || 'Error al arreglar la conciliación'
    }
  }
)

export const getRecaudadoraBalance = createAsyncThunk(
  'appFiles/getRecaudadoraBalance',
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/saldo-cuenta-recaudadora`
      )
      return response.data
    } catch (error) {
      console.error('Error fetching balance:', error)
      throw error.response?.data?.message || 'Error al obtener el saldo'
    }
  }
)

export const getPaginatedMovements = createAsyncThunk(
  'appFiles/getPaginatedMovements',
  async ({ page = 1, itemsPerPage = 10, filters = {} }) => {
    try {
      const params = {
        page,
        items_per_page: itemsPerPage,
        ...filters
      }
      
      const response = await axios.get(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/movimientos-paginado`,
        { params }
      )
      return response.data
    } catch (error) {
      console.error('Error fetching movements:', error)
      throw error.response?.data?.message || 'Error al obtener los movimientos'
    }
  }
)

export const getCVUBalances = createAsyncThunk(
  'appFiles/getCVUBalances',
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/saldo-cvus-banza`
      )
      return response.data
    } catch (error) {
      console.error('Error fetching CVU balances:', error)
      throw error.response?.data?.message || 'Error al obtener los saldos CVU'
    }
  }
)

const initialState = {
  conciliationErrors: [],
  conciliationErrorsLoading: false,
  files: {},
  filesLoading: {},
  downloadedFiles: {},
  downloadLoading: {},
  balance: null,
  balanceLoading: false,
  movements: {
    list: [],
    total: 0,
    currentPage: 1
  },
  movementsLoading: false,
  cvuBalances: {
    totalBalance: 0,
    balances: []
  },
  cvuBalancesLoading: false,
  conciliationDetail: null,
  conciliationDetailLoading: false,
  fixConciliationLoading: false,
  error: null
}

const contentFiles = createSlice({
  name: 'contentFiles',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null
    },
    clearDownloadedFile: (state, action) => {
      const fileType = action.payload
      state.downloadedFiles[fileType] = null
      state.downloadLoading[fileType] = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getFilesList.pending, (state, action) => {
        const fileType = action.meta.arg
        state.filesLoading[fileType] = true
        state.error = null
      })
      .addCase(getFilesList.fulfilled, (state, action) => {
        const { fileType, data } = action.payload
        state.files[fileType] = data
        state.filesLoading[fileType] = false
      })
      .addCase(getFilesList.rejected, (state, action) => {
        const fileType = action.meta.arg
        state.filesLoading[fileType] = false
        state.error = action.error.message
      })
      .addCase(downloadFile.pending, (state, action) => {
        const { fileType } = action.meta.arg
        state.downloadLoading[fileType] = true
        state.error = null
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        const { fileType } = action.payload
        state.downloadLoading[fileType] = false
      })
      .addCase(downloadFile.rejected, (state, action) => {
        const { fileType } = action.meta.arg
        state.downloadLoading[fileType] = false
        state.error = action.error.message
      })
      .addCase(getRecaudadoraBalance.pending, (state) => {
        state.balanceLoading = true
        state.error = null
      })
      .addCase(getRecaudadoraBalance.fulfilled, (state, action) => {
        state.balanceLoading = false
        state.balance = action.payload
      })
      .addCase(getRecaudadoraBalance.rejected, (state, action) => {
        state.balanceLoading = false
        state.error = action.error.message
      })
      .addCase(getPaginatedMovements.pending, (state) => {
        state.movementsLoading = true
        state.error = null
      })
      .addCase(getPaginatedMovements.fulfilled, (state, action) => {
          state.movementsLoading = false
          state.movements = {
              list: action.payload.list || [],
              total: action.payload.numberRecordsTotal || 0,
              currentPage: action.payload.nextPageNumber ? action.payload.nextPageNumber - 1 : 1
          }
      })
      .addCase(getPaginatedMovements.rejected, (state, action) => {
        state.movementsLoading = false
        state.error = action.error.message
      })
      .addCase(getCVUBalances.pending, (state) => {
        state.cvuBalancesLoading = true
        state.error = null
      })
      .addCase(getCVUBalances.fulfilled, (state, action) => {
        state.cvuBalancesLoading = false
        state.cvuBalances = {
          totalBalance: action.payload.total_balance,
          balances: action.payload.balances
        }
      })
      .addCase(getCVUBalances.rejected, (state, action) => {
        state.cvuBalancesLoading = false
        state.error = action.error.message
      })
      .addCase(getDetailConciliation.pending, (state) => {
        state.conciliationDetailLoading = true
        state.error = null
      })
      .addCase(getDetailConciliation.fulfilled, (state, action) => {
        state.conciliationDetailLoading = false
        state.conciliationDetail = action.payload
      })
      .addCase(getDetailConciliation.rejected, (state, action) => {
        state.conciliationDetailLoading = false
        state.error = action.error.message
      })
      .addCase(fixConciliation.pending, (state) => {
        state.fixConciliationLoading = true
        state.error = null
      })
      .addCase(fixConciliation.fulfilled, (state) => {
        state.fixConciliationLoading = false
      })
      .addCase(fixConciliation.rejected, (state, action) => {
        state.fixConciliationLoading = false
        state.error = action.error.message
      })
      .addCase(getConciliationErrors.pending, (state) => {
        state.conciliationErrorsLoading = true
        state.error = null
      })
      .addCase(getConciliationErrors.fulfilled, (state, action) => {
        state.conciliationErrorsLoading = false
        state.conciliationErrors = action.payload.errors
      })
      .addCase(getConciliationErrors.rejected, (state, action) => {
        state.conciliationErrorsLoading = false
        state.error = action.error.message
      })
  }
})

export const { clearError, clearDownloadedFile } = contentFiles.actions

// Export both as named and default
export const { reducer } = contentFiles
export default reducer