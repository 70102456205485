// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// -- GET Next Holidays
export const getNextHolidays = createAsyncThunk('holidays/getNextHolidays', async () => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/holidays/list/next`);
  return {
    data: response.data
  }
})

// -- GET Previous Holidays
export const getPreviousHolidays = createAsyncThunk('holidays/getPreviousHolidays', async () => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/holidays/list/previous`);
  return {
    data: response.data
  }
})

// -- POST Create Holiday
export const createHoliday = createAsyncThunk('holidays/createHoliday', async (holiday) => {
  const response = await axios.post(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/holidays/`, holiday);
  return {
    data: response.data
  }
})

// -- DELETE Holiday
export const deleteHoliday = createAsyncThunk('holidays/deleteHoliday', async (date) => {
  const response = await axios.delete(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/holidays/${date}`);
  return {
    data: response.data
  }
})

export const holidaysSlice = createSlice({
  name: 'holidays',
  initialState: {
    nextHolidays: [],
    previousHolidays: [],
    holidaysLoading: true,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getNextHolidays.fulfilled, (state, action) => {
        state.nextHolidays = action.payload.data.holidays;
        state.holidaysLoading = false;
      })
      .addCase(getNextHolidays.pending, (state, action) => {
        state.holidaysLoading = true;
      })
      .addCase(getPreviousHolidays.fulfilled, (state, action) => {
        state.previousHolidays = action.payload.data.holidays;
        state.holidaysLoading = false;
      })
      .addCase(getPreviousHolidays.pending, (state, action) => {
        state.holidaysLoading = true;
      })
  }
})

export default holidaysSlice.reducer;
