// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';

// -- Onboarding Clients
export const getOnboardingClients = createAsyncThunk(
  'appInvoice/getOnboardingClients',
  async (params) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/onboarding/clients?itemsPage=${params.itemsPerPage}&page=${
        params.page
      }&text=${params.text_field}&fromDate=${params.dateFrom}&toDate=${
        params.dateTo
      }&status=${params.status}&step=${params.step}`
    );
    return {
      params,
      data: response.data,
    };
  }
);

// -----------------------

// -- Onboarding Client Information
export const getOnboardingClientInformation = createAsyncThunk(
  'appInvoice/getOnboardingClientInformation',
  async (params) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/onboarding/client/${params}`
    );
    return {
      params,
      data: response.data.clientBasicInfo,
    };
  }
);

// -- Sync Client Tenure and Position
export const SyncClient = createAsyncThunk(
  'appInvoice/getSyncClient',
  async (params) => {
    const response = await axios.post(
      `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/clients/sync/${
        params.id
      }`,
      params.payload
    );
    return {
      params,
      data: response.data.sucess,
    };
  }
);

// ------------------------

// -- Update User Legal Information
export const putClientLegalInformation = createAsyncThunk(
  'appInvoice/putClientLegalInformation',
  async (params) => {
    const response = await axios.put(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/onboarding/client-legal-update/${params.uuid}`,
      params.update_params
    );
    return {
      params,
      data: response.data.clientBasicInfo,
    };
  }
);

// -------------------------

// -- Update User Info
export const putClientInfo = createAsyncThunk(
  'appInvoice/putClientInfo',
  async (params) => {
    const response = await axios.put(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/onboarding/client-update/${params.uuid}`,
      params.update_params
    );
    return {
      params,
      data: response.data.clientBasicInfo,
    };
  }
);

// -------------------------

// -- Get Active client list
export const getActivedClients = createAsyncThunk(
  'appInvoice/getActivedClients',
  async (params) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/clients/list?itemsPage=${params.itemsPerPage}&page=${
        params.page
      }&textField=${params.text_field}&status=${params.clientStatus}&refCode=${
        params.refCode
      }&accountNumber=${params.account}&dateFrom=${params.dateFrom}&dateTo=${
        params.dateTo
      }`
    );
    return {
      params,
      data: response.data.clients,
    };
  }
);

export const exportClients = createAsyncThunk(
  'appInvoice/getActivedClients/export',
  async (params) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/clients/list/export?textField=${params.text_field}&status=${
        params.clientStatus
      }&refCode=${params.refCode}&accountNumber=${params.account}&dateFrom=${
        params.dateFrom
      }&dateTo=${params.dateTo}`
    );
    return {
      params,
      data: response.data.exportUrl,
    };
  }
);

export const exportOnboardingClients = createAsyncThunk(
  'appInvoice/onboarding/export',
  async (params) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/onboarding/clients/export?text=${
        params.text_field
      }&fromDate=${params.dateFrom}&toDate=${params.dateTo}&status=${
        params.status
      }&step=${params.step}`
    );
    return {
      params,
      data: response.data.exportUrl,
    };
  }
);

// -------------------------

// -- Reject Onboarding Client
export const rejectOnboardingClient = createAsyncThunk(
  'onboarding/rejectOnboardingClient',
  async (params) => {
    const response = await axios.post(
      `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/onboarding/reject/${
        params.id
      }`,
      params.payload
    );
    return {
      params,
      data: response.data.user,
    };
  }
);

// -------------------------

// -- Validate Onboarding Client
export const validateOnboardingClient = createAsyncThunk(
  'onboarding/validateOnboardingClient',
  async (params) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/onboarding/validate/${params}`
    );
    return {
      params,
      data: response.data.user,
    };
  }
);

// -------------------------

// -- Update Client Status
export const updateClientStatus = createAsyncThunk(
  'clients/updateClientStatus',
  async (params) => {
    const response = await axios.put(
      `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/clients/status/${
        params.id
      }`,
      params.payload
    );
    return {
      params,
      data: response.data.client,
    };
  }
);

// -------------------------

// -- Get Bank Account
export const getBankAccount = createAsyncThunk(
  'appInvoice/getBankAccount/information',
  async (params) => {
    const response = await axios
      .get(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/bank_account/${
          params.uuid
        }/${params.cbualias}/${params.moneda}`
      )
      .then(function(response) {
        alert(JSON.stringify(response.data));
      });
    return {
      params,
      data: response.data,
    };
  }
);

// -- Get Bank Accounts
export const getBankAccounts = createAsyncThunk(
  'appInvoice/getBankAccounts',
  async (params) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/bank_accounts/${params}`
    );
    return {
      params,
      data: response.data,
    };
  }
);
// -- Post Unlock Bank Account
export const unlockBankAccount = createAsyncThunk(
  'appInvoice/postBankAccounts',
  async (payload) => {
    const response = await axios.post(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/bank_account/manual/unlock_cbu`,
      payload
    );
    return response.data;
  }
);

// -- Get Client Information
export const getActivedClientInformation = createAsyncThunk(
  'appInvoice/getActivedClient/information',
  async (params) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/clients/${params}`
    );
    return {
      params,
      data: response.data.client,
    };
  }
);
// -- Get Scheduled Events
export const getScheduledEvents = createAsyncThunk(
  'appInvoice/getScheduledEvents',
  async (user_id) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/clients/scheduled_events/${user_id}`
    );
    return response.data;
  }
);
// ** Get Stop Loss
export const getStopLoss = createAsyncThunk(
  'client/getStopLoss',
  async (user_id) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/clients/stoploss/${user_id}`
    );
    return response.data;
  }
);
// -------------------------

// ** Get investment list
export const getInvestmentListOperation = createAsyncThunk(
  'client/getInvestmentListOperation',
  async ({ uuid, page, itemsPerPage, fromDate, toDate }) => {
    let url = `${
      process.env.REACT_APP_ENV_BASE_URL
    }/backoffice/operation-monitor/list/client?itemsPage=${itemsPerPage}&page=${page}&uuid=${uuid}`;
    if (fromDate) url += `&fromDate=${fromDate}`;
    if (toDate) url += `&toDate=${toDate}`;

    const response = await axios.get(url);
    return response.data;
  }
);
// -------------------------

// ** Reschedule MEP operation
export const rescheduleMepOperation = createAsyncThunk(
  'client/rescheduleMepOperation',
  async ({ userUuid, operationId }) => {
    const response = await axios.put(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/clients/mep/reschedule/${userUuid}/${operationId}`
    );
    return response.data;
  }
);
// ** Cancel MEP Operation
export const cancelMepOperation = createAsyncThunk(
  'client/cancelMepOperation',
  async ({ userUuid, operationId }) => {
    const response = await axios.put(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/clients/mep/cancel/${userUuid}/${operationId}`
    );
    return response.data;
  }
);

// -- Get Client Documentation
export const getActivedClientDocumentation = createAsyncThunk(
  'appInvoice/getActivedClient/documentation',
  async (params) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/onboarding/specific-client-documentation/${params}`
    );
    return {
      params,
      data: response.data.clientDocumentation,
    };
  }
);

// -------------------------

export const getClientLoadedInformation = createAsyncThunk(
  'appUser/getClientLoadedInformation',
  async (id) => {
    const response = await axios.get(`/api/clients/loadedInformation/${id}`);
    return {
      data: response.data,
    };
  }
);

// -- Onboarding Client Legal
export const getOnboardingClientLegalInformation = createAsyncThunk(
  'appInvoice/getOnboardingClientLegalInformation',
  async (params) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/onboarding/specific-client-legal/${params}`
    );
    return {
      params,
      data: response.data.clientLegal,
    };
  }
);

// -------------------------

// -- Client file upload
export const postClientFile = createAsyncThunk(
  'appInvoice/postClientFile',
  async (params, { dispatch }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/documents/save`,
      params
    );
    if (response) dispatch(getActivedClientInformation(params.owner_uuid));
    return {
      params,
      data: response.data.clientLegal,
    };
  }
);

// -------------------------
// Fetch cashin cashout data
export const fetchCashinCashoutData = createAsyncThunk(
  'cashinCashout/fetchCashinCashoutData',
  async ({ uuid, page, itemsPage, fromDate, toDate, currency }) => {
    const params = { page, itemsPage };
    if (fromDate) params.fromDate = fromDate;
    if (toDate) params.toDate = toDate;
    if (currency) params.currency = currency;

    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/cashin_cashout/list/${uuid}`,
      { params }
    );
    return response.data;
  }
);
// Fetch client SMS validation information
export const fetchClientSmsValidationInfo = createAsyncThunk(
  'client/fetchClientSmsValidationInfo',
  async (params) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/onboarding/sms-codes-info/${params}`
    );
    return response.data;
  }
);

// Reset SMS validation attempts
export const resetClientSmsValidationAttempts = createAsyncThunk(
  'client/resetClientSmsValidationAttempts',
  async (uuid) => {
    const response = await axios.patch(
      `${
        process.env.REACT_APP_ENV_BASE_URL
      }/backoffice/onboarding/reset-sms-codes/${uuid}`
    );
    return response.data.result;
  }
);
// ** Action to download
export const downloadReceipt = createAsyncThunk(
  'investments/downloadReceipt',
  async ({ instrument_type, uuid, order_id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_ENV_BASE_URL
        }/backoffice/operation-monitor/order/export`,
        {
          params: { instrument_type, uuid, order_id },
        }
      );
      const { url } = response.data;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      link.remove();
      return url;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// -------------------------

// -- Update User Legal Information
export const putActiveClientInformation = createAsyncThunk(
  'client/putActiveClientInformation',
  async ({ uuid, clientData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/clients/edit/${uuid}`,
        clientData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// -------------------------

// ** Reset Email Validation Attempts
export const resetClientEmailValidationAttempts = createAsyncThunk(
  "client/resetClientEmailValidationAttempts",
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/onboarding/unlock_email`,
        { uuid }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Error al resetear intento de cambio de email");
    }
  }
);
// -------------------------

// ** Reset Photos Validation Attempts
export const resetClientPhotosValidationAttempts = createAsyncThunk(
  "client/resetClientPhotosValidationAttempts",
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/onboarding/reset_photos/${uuid}`
      );
      return response.data.result;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Error al resetear las fotos");
    }
  }
);
// -------------------------

// ** Reset Onboarding
export const resetClientOnboarding = createAsyncThunk(
  "client/resetClientOnboarding",
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/onboarding/delete/${uuid}`
      );
      return response.data.result;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Error al resetear el onboarding");
    }
  }
);
// -------------------------

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    // Client
    clients: [],
    clientsListLoading: true,
    clientsNumberOfRecords: 0,
    clientPages: 1,
    allClientData: [],
    // totalClient: 1,
    clientParams: {},
    clientInformation: {},
    clientDocumentation: {},
    clientInfoLoading: true,
    clientDocumentationLoading: true,
    // Onboarding client
    onboardingClientLoading: true,
    onboardingClients: [],
    onboardingClientInfo: {},
    onboardingClientLegal: {},
    onboardingClientDocumentation: {},

    clientLoadedInformation: [],
    // scheduled events
    scheduledEvents: [],
    scheduledEventsLoading: false,
    // stop loss
    stopLoss: [],
    stopLossLoading: false,
    // investment list
    investmentList: [],
    isLoadingInvestmentList: false,
    fetchErrorInvestmentList: null,
    currentPageInvestmentList: 1,
    itemsPerPageInvestmentList: 10,
    totalRecordsInvestmentList: 0,
    // Bank Accounts
    clientBankAccounts: {
      accounts: [],
    },
    unlockBankAccountsCBU: { errorCode: '', message: '' },
    loadingBankAccounts: false,
    // cashin cashout
    transactionsCashinCashout: [],
    isLoadingCashinCashout: false,
    fetchErrorCashinCashout: null,
    currentPageCashinCashout: 1,
    itemsPerPageCashinCashout: 10,
    totalRecordsCashinCashout: 0,
    // Fetch SMS validation info
    loadingFetchSmsInfo: false,
    clientSmsValidationInfo: [],
    fetchSmsInfoError: null,
    // Reset SMS validation attempts
    loadingResetSmsAttempts: false,
    resetSmsAttemptsResult: null,
    resetSmsAttemptsError: null,
    // Download receipt
    isLoadingDownload: false,
    // Reset Email attempts
    resetEmailAttemptsResult: null,
    resetEmailAttemptsError: null,
    loadingResetEmail: false,
     // Reset Photos attempts
     resetPhotosAttemptsResult: null,
     resetPhotosAttemptsError: null,
     loadingResetPhotos: false,
     // Reset Onboarding
     resetOnboardingResult: null,
     resetOnboardingError: null,
     loadingResetOnboarding: false,
    // PSP
    cvuBalanceBind: "N/A",
    cvuBalanceBanza: "N/A",
    loadingCVUBalance: false,
    syncingCVU: false,
    cvuError: null
  },
  reducers: {
    cleanUnlockBankAccount: (state) => {
      state.unlockBankAccountsCBU.errorCode = '';
      state.unlockBankAccountsCBU.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivedClients.fulfilled, (state, action) => {
        state.clients = action.payload.data.list;
        state.clientsNumberOfRecords = action.payload.data.numberRecordsTotal;
        state.allClientData = action.payload.allData;
        state.clientPages = action.payload.numberOfPages;
        state.totalClient = action.payload.totalPages;
        state.clientParams = action.payload.data;
        state.clientsListLoading = false;
      })
      .addCase(getActivedClients.pending, (state, action) => {
        state.clientsListLoading = true;
      })
      .addCase(getActivedClientInformation.fulfilled, (state, action) => {
        state.clientInformation = action.payload.data;
        state.clientInfoLoading = false;
      })
      .addCase(getActivedClientDocumentation.pending, (state, action) => {
        state.clientDocumentationLoading = true;
      })
      .addCase(getActivedClientDocumentation.fulfilled, (state, action) => {
        state.clientDocumentation = action.payload.data;
        state.clientDocumentationLoading = false;
      })
      .addCase(updateClientStatus.fulfilled, (state, action) => {
        state.clientInformation = action.payload.data;
        state.clientInfoLoading = false;
      })
      .addCase(getActivedClientInformation.pending, (state, action) => {
        state.clientInfoLoading = true;
      })
      .addCase(updateClientStatus.pending, (state, action) => {
        state.clientInfoLoading = true;
      })
      .addCase(getOnboardingClientInformation.fulfilled, (state, action) => {
        state.onboardingClientInfo = action.payload.data;
        state.onboardingClientLegal = action.payload.data.legalInformation;
        state.onboardingClientDocumentation = action.payload.data.documentation;
        state.onboardingClientLoading = false;
      })
      .addCase(rejectOnboardingClient.fulfilled, (state, action) => {
        let payloadData = action.payload.data;
        payloadData.onboarding_status = 'REJECTED';
        state.onboardingClientInfo = payloadData;
        state.onboardingClientLegal = action.payload.data.legalInformation;
        state.onboardingClientDocumentation = action.payload.data.documentation;
        state.onboardingClientLoading = false;
      })
      .addCase(validateOnboardingClient.fulfilled, (state, action) => {
        state.onboardingClientInfo = action.payload.data;
        state.onboardingClientLegal = action.payload.data.legalInformation;
        state.onboardingClientDocumentation = action.payload.data.documentation;
        state.onboardingClientLoading = false;
      })
      .addCase(putClientLegalInformation.pending, (state, action) => {
        state.onboardingClientLoading = true;
      })
      .addCase(putClientInfo.pending, (state, action) => {
        state.onboardingClientLoading = true;
      })
      .addCase(getOnboardingClientInformation.pending, (state, action) => {
        state.onboardingClientLoading = true;
      })
      .addCase(rejectOnboardingClient.pending, (state, action) => {
        state.onboardingClientLoading = true;
      })
      .addCase(validateOnboardingClient.pending, (state, action) => {
        state.onboardingClientLoading = true;
      })
      .addCase(putClientLegalInformation.fulfilled, (state, action) => {
        state.onboardingClientLegal = action.payload.data.legalInformation;
        state.onboardingClientLoading = false;
      })
      .addCase(putClientInfo.fulfilled, (state, action) => {
        state.onboardingClientInfo = action.payload.data;
        state.onboardingClientLoading = false;
      })
      .addCase(getOnboardingClients.fulfilled, (state, action) => {
        state.onboardingClients = action.payload.data.list;
        state.clientsNumberOfRecords = action.payload.data.numberRecordsTotal;
        state.totalClient = action.payload.numberOfPages;
        state.clientsListLoading = false;
      })
      .addCase(exportClients.fulfilled, (state, action) => {
        const url = action.payload.data;
        window.open(url, '_blank');
      })
      .addCase(exportOnboardingClients.fulfilled, (state, action) => {
        const url = action.payload.data;
        window.open(url, '_blank');
      })
      .addCase(getBankAccounts.pending, (state, action) => {
        state.loadingBankAccounts = true;
      })

      .addCase(getBankAccounts.fulfilled, (state, action) => {
        state.clientBankAccounts.accounts = action.payload.data.accounts;
        state.loadingBankAccounts = false;
        state.cbuUnlocked = false;
      })
      .addCase(unlockBankAccount.fulfilled, (state, action) => {
        state.unlockBankAccountsCBU.errorCode =
          action.payload.dataBody.errorCode;
        state.unlockBankAccountsCBU.message = action.payload.dataBody.message;
      })
      .addCase(unlockBankAccount.rejected, (state, action) => {
        state.unlockBankAccountsCBU.errorCode =
          action.payload.dataBody.errorCode;
        state.unlockBankAccountsCBU.message = action.payload.dataBody.message;
      })
      .addCase(getScheduledEvents.pending, (state) => {
        state.scheduledEventsLoading = true;
      })
      .addCase(getScheduledEvents.fulfilled, (state, action) => {
        state.scheduledEvents = action.payload.events;
        state.scheduledEventsLoading = false;
      })
      .addCase(getScheduledEvents.rejected, (state, action) => {
        state.scheduledEventsLoading = false;
      })
      .addCase(getStopLoss.pending, (state) => {
        state.stopLossLoading = true;
      })
      .addCase(getStopLoss.fulfilled, (state, action) => {
        state.stopLoss = action.payload.process_list;
        state.stopLossLoading = false;
      })
      .addCase(getStopLoss.rejected, (state, action) => {
        state.stopLossLoading = false;
      })
      // Investment List
      .addCase(getInvestmentListOperation.pending, (state) => {
        state.isLoadingInvestmentList = true;
      })
      .addCase(getInvestmentListOperation.fulfilled, (state, action) => {
        state.isLoadingInvestmentList = false;
        state.investmentList = action.payload.list;
        state.totalRecordsInvestmentList = action.payload.numberRecordsTotal;
      })
      .addCase(getInvestmentListOperation.rejected, (state, action) => {
        state.isLoadingInvestmentList = false;
        state.fetchErrorInvestmentList = action.error.message;
      })
      .addCase(rescheduleMepOperation.pending, (state) => {
        state.rescheduleLoading = true;
      })
      .addCase(rescheduleMepOperation.fulfilled, (state, action) => {
        state.rescheduleLoading = false;
        state.rescheduleData = action.payload;
        state.rescheduleError = null;

        const index = state.investmentList.findIndex(
          (investment) => investment.mepId === action.payload.id
        );
        if (index !== -1) {
          state.investmentList[index] = {
            ...state.investmentList[index],
            ...action.payload,
          };
        }
      })
      .addCase(rescheduleMepOperation.rejected, (state, action) => {
        state.rescheduleLoading = false;
        state.rescheduleError = action.error.message;
      })
      .addCase(cancelMepOperation.pending, (state) => {
        state.cancelLoading = true;
      })
      .addCase(cancelMepOperation.fulfilled, (state, action) => {
        state.cancelLoading = false;
        state.cancelData = action.payload;
        state.cancelError = null;
      })
      .addCase(cancelMepOperation.rejected, (state, action) => {
        state.cancelLoading = false;
        state.cancelError = action.payload?.response?.data?.message
          ? action.payload.response.data.message
          : action.error.message;
      })
      // Cashin Cashout
      .addCase(fetchCashinCashoutData.pending, (state) => {
        state.isLoadingCashinCashout = true;
      })
      .addCase(fetchCashinCashoutData.fulfilled, (state, action) => {
        state.isLoadingCashinCashout = false;
        state.transactionsCashinCashout = action.payload.list;
        state.totalRecordsCashinCashout = action.payload.numberRecordsTotal;
      })
      .addCase(fetchCashinCashoutData.rejected, (state, action) => {
        state.isLoadingCashinCashout = false;
        state.fetchErrorCashinCashout = action.error.message;
      })
      // Fetch SMS validation info
      .addCase(fetchClientSmsValidationInfo.pending, (state) => {
        state.loadingFetchSmsInfo = true;
      })
      .addCase(fetchClientSmsValidationInfo.fulfilled, (state, action) => {
        state.loadingFetchSmsInfo = false;
        state.clientSmsValidationInfo = action.payload;
        state.fetchSmsInfoError = null;
      })
      .addCase(fetchClientSmsValidationInfo.rejected, (state, action) => {
        state.loadingFetchSmsInfo = false;
        state.fetchSmsInfoError = action.payload?.response?.data?.message
          ? action.payload.response.data.message
          : action.error.message;
      })
      // Reset SMS validation attempts
      .addCase(resetClientSmsValidationAttempts.pending, (state) => {
        state.loadingResetSmsAttempts = true;
      })
      .addCase(resetClientSmsValidationAttempts.fulfilled, (state, action) => {
        state.loadingResetSmsAttempts = false;
        state.resetSmsAttemptsResult = action.payload;
        state.resetSmsAttemptsError = null;
      })
      .addCase(resetClientSmsValidationAttempts.rejected, (state, action) => {
        state.loadingResetSmsAttempts = false;
        state.resetSmsAttemptsError = action.payload?.response?.data?.message
          ? action.payload.response.data.message
          : action.error.message;
      })
      // download receipt
      .addCase(downloadReceipt.pending, (state) => {
        state.isLoadingDownload = true;
      })
      .addCase(downloadReceipt.fulfilled, (state) => {
        state.isLoadingDownload = false;
      })
      .addCase(downloadReceipt.rejected, (state) => {
        state.isLoadingDownload = false;
      })
      // put active client information
      .addCase(putActiveClientInformation.pending, (state) => {
        state.clientInfoLoading = true;
      })
      .addCase(putActiveClientInformation.fulfilled, (state, action) => {
        state.clientInformation = {
          ...state.clientInformation,
          ...action.payload,
        };
        state.clientInfoLoading = false;
      })
      .addCase(putActiveClientInformation.rejected, (state, action) => {
        state.clientInfoLoading = false;
      })
      // reset Email 
      .addCase(resetClientEmailValidationAttempts.pending, (state) => {
        state.loadingResetEmail = true;
        state.resetEmailAttemptsError = null;
      })
      .addCase(resetClientEmailValidationAttempts.fulfilled, (state, action) => {
        state.loadingResetEmail = false;
        state.resetEmailAttemptsResult = action.payload;
      })
      .addCase(resetClientEmailValidationAttempts.rejected, (state, action) => {
        state.loadingResetEmail = false;
        state.resetEmailAttemptsError = action.payload;
      })
      // reset Photos 
      .addCase(resetClientPhotosValidationAttempts.pending, (state) => {
        state.loadingResetPhotos = true;
        state.resetPhotosAttemptsError = null;
      })
      .addCase(resetClientPhotosValidationAttempts.fulfilled, (state, action) => {
        state.loadingResetPhotos = false;
        state.resetPhotosAttemptsResult = action.payload;
      })
      .addCase(resetClientPhotosValidationAttempts.rejected, (state, action) => {
        state.loadingResetPhotos = false;
        state.resetPhotosAttemptsError = action.payload;
      })
      // reset Onboarding
      .addCase(resetClientOnboarding.pending, (state) => {
        state.loadingResetOnboarding = true;
        state.resetOnboardingError = null;
      })
      .addCase(resetClientOnboarding.fulfilled, (state, action) => {
        state.loadingResetOnboarding = false;
        state.resetOnboardingResult = action.payload;
      })
      .addCase(resetClientOnboarding.rejected, (state, action) => {
        state.loadingResetOnboarding = false;
        state.resetOnboardingError = action.payload;
      })
      .addCase(getCVUBalanceBind.pending, (state) => {
        state.loadingCVUBalance = true;
        state.cvuError = null;
      })
      .addCase(getCVUBalanceBind.fulfilled, (state, action) => {
        state.cvuBalanceBind = action.payload;
        state.loadingCVUBalance = false;
      })
      .addCase(getCVUBalanceBind.rejected, (state, action) => {
        state.loadingCVUBalance = false;
        state.cvuError = action.error.message;
      })
      .addCase(getCVUBalanceBanza.pending, (state) => {
        state.loadingCVUBalance = true;
        state.cvuError = null;
      })
      .addCase(getCVUBalanceBanza.fulfilled, (state, action) => {
        state.cvuBalanceBanza = action.payload;
        state.loadingCVUBalance = false;
      })
      .addCase(getCVUBalanceBanza.rejected, (state, action) => {
        state.loadingCVUBalance = false;
        state.cvuError = action.error.message;
      })
      .addCase(syncCVU.pending, (state) => {
        state.syncingCVU = true;
        state.cvuError = null;
      })
      .addCase(syncCVU.fulfilled, (state) => {
        state.syncingCVU = false;
      })
      .addCase(syncCVU.rejected, (state, action) => {
        state.syncingCVU = false;
        state.cvuError = action.error.message;
      });
  },
});
export const { cleanUnlockBankAccount } = clientSlice.actions;
export default clientSlice.reducer;

// addresses: [],
// addressesParams: {},

// inscriptions: [],
// inscriptionParams: {},

// compositions: [],
// compositionParams: {},

// beneficiaries: [],
// beneficiarieParams: {},

// .addCase(getAddresses.fulfilled, (state, action) => {
//   state.addresses = action.payload.data
//   state.addressesParams = action.payload.params
// })
// .addCase(getInscriptions.fulfilled, (state, action) => {
//   state.inscriptions = action.payload.data
//   state.inscriptionParams = action.payload.params
// })
// .addCase(getCompositions.fulfilled, (state, action) => {
//   state.compositions = action.payload.data
//   state.compositionParams = action.payload.params
// })
// .addCase(getBeneficiaries.fulfilled, (state, action) => {
//   state.beneficiaries = action.payload.data
//   state.beneficiarieParams = action.payload.params
// })
// .addCase(getOnboardingClientLegalInformation.fulfilled, (state,action) => {
//   state.onboardingClientLegal = action.payload.data
// })

// export const deleteInvoice = createAsyncThunk('appInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
//   await axios.delete('/apps/invoice/delete', { id })
//   await dispatch(getAddresses(getState().invoice.params))
//   return id
// })

// export const getAddresses = createAsyncThunk('appInvoice/getAddresses', async params => {
//   const response = await axios.get('/apps/society-addresses/invoices', params)
//   return {
//     params,
//     data: response.data.invoices,
//   }
// })

// export const getInscriptions = createAsyncThunk('appInvoice/getInscriptions', async params => {
//   const response = await axios.get('/apps/society-inscriptions/invoices', params)
//   return {
//     params,
//     data: response.data.invoices,
//   }
// })

// export const getCompositions = createAsyncThunk('appInvoice/getCompositions', async params => {
//   const response = await axios.get('/apps/shareholding-composition/invoices', params)
//   return {
//     params,
//     data: response.data.invoices,
//   }
// })

// export const getBeneficiaries = createAsyncThunk('appInvoice/getBeneficiaries', async params => {
//   const response = await axios.get('/apps/final-beneficiaries/invoices', params)
//   return {
//     params,
//     data: response.data.invoices,
//   }
// })

// export const getOnboardingUserDetail = createAsyncThunk('appInvoice/getOnboardingUserDetail', async params => {
//   const response = await axios.get('/apps/final-beneficiaries/invoices', params)
//   return {
//     params,
//     data: response.data.invoices,
//   }
// })



// Add these new actions after the existing ones
export const getCVUBalanceBind = createAsyncThunk(
  'client/getCVUBalanceBind',
  async (uuid) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/cvu-balance-bind/${uuid}`
    );
    return response.data;
  }
);

export const getCVUBalanceBanza = createAsyncThunk(
  'client/getCVUBalanceBanza',
  async (uuid) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/cvu-balance-banza/${uuid}`
    );
    return response.data;
  }
);

export const syncCVU = createAsyncThunk(
  'client/syncCVU',
  async (uuid) => {
    const response = await axios.post(
      `${process.env.REACT_APP_ENV_BASE_URL}/backoffice/sync`,
      { uuid }
    );
    return response.data;
  }
);