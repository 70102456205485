// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getReason = createAsyncThunk('savingModule/getReason', async params => {
  const response = await axios.get('/saving/reasons', params)
  return {
    params,
    data: response.data.reasons,
    allData: response.data.allData,
    totalPages: response.data.total
  }
})

// export const deleteSpecie = createAsyncThunk('fund/deleteSpecie', async (id, { dispatch, getState }) => {
//   await axios.delete('/funds/delete', { id })
//   //await dispatch(getAddresses(getState().reason.params))
//   return id
// })

export const savingModuleSlice = createSlice({
  name: 'reasons',
  initialState: {
    items: [],
    allReasonData: [],
    totalReason: 1,
    reasonParams: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getReason.fulfilled, (state, action) => {
      state.items = action.payload.data
      state.allReasonData = action.payload.allData
      state.totalReason = action.payload.totalPages
      state.reasonParams = action.payload.params
    })
  }
})

export default savingModuleSlice.reducer