// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getCertificates = createAsyncThunk('certificate/getCertificates', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/certificates`, {
      params: { page: params.page, itemsPage: params.itemsPerPage }
    });
    return {
      params,
      data: response.data,
      totalRecords: response.data.numberRecordsTotal,
      totalPages: response.data.numberOfPages,
      nextPage: response.data.nextPage,
      previousPage: response.data.previousPage 
    };
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

export const getSpecificCertificate = createAsyncThunk('certificate/getSpecificCertificate', async (ticker, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/certificate/${ticker}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export const resetSpecificCertificate = createAsyncThunk('certificate/resetSpecificCertificate', async params => {
  return {
    params,
    data: {},
  }
})

export const getTags = createAsyncThunk('tags/getTags', async params => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/markettags?page=1&itemsPage=10`, params)
  return {
    params,
    data: response.data.list,
  }
})

export const getTypes = createAsyncThunk('certificate/getTypes', async params => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/certificatecategories?page=1&itemsPage=10`, params)
  return {
    params,
    data: response.data.list,
  }
})

export const putCertificate = createAsyncThunk('certificate/putCertificate', async params => {
  const response = await axios.put(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/certificate`, params)
  return response
})

export const disableCertificate = createAsyncThunk('certificate/disableCertificate', async ticker => {
  const response = await axios.delete(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/certificate/${ticker}`)
  return response
})

export const certificateSlice = createSlice({
  name: 'certificates',
  initialState: {
    items: [],
    totalPages: 1,
    currentPage: 1,
    certificate:{},
    tags:[],
    types:[],
    loadingCertificates: false,
    loadingSpecificCertificate: false,
    loadingTags: false,
    loadingTypes: false,
    errorCertificate: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getCertificates.pending, (state) => {
      state.loadingCertificates = true;
    })
    .addCase(getCertificates.fulfilled, (state, action) => {
      state.items = action.payload.data.list;
      state.totalRecords = action.payload.data.numberRecordsTotal;
      state.totalPages = action.payload.data.numberOfPages;
      state.currentPage = action.payload.params.page;
      state.certificateParams = action.payload.params;
      state.loadingCertificates = false;
    })
    .addCase(getCertificates.rejected, (state, action) => {
      state.loadingCertificates = false;
      state.errorCertificate = action.payload;
    })
    .addCase(getSpecificCertificate.pending, (state) => {
      state.loadingSpecificCertificate = true;
    })
    .addCase(getSpecificCertificate.fulfilled, (state, action) => {
      state.certificate = action.payload;
      state.loadingSpecificCertificate = false;
    })
    .addCase(getSpecificCertificate.rejected, (state, action) => {
      state.loadingSpecificCertificate = false;
      state.errorCertificate = action.payload;
    })
    .addCase(resetSpecificCertificate.pending, (state) => {
      state.loadingSpecificCertificate = true;
    })
    .addCase(resetSpecificCertificate.fulfilled, (state, action) => {
      state.certificate = action.payload;
      state.loadingSpecificCertificate = false;
    })
    .addCase(resetSpecificCertificate.rejected, (state, action) => {
      state.loadingSpecificCertificate = false;
      state.errorCertificate = action.payload;
    })
    .addCase(getTags.pending, (state) => {
      state.loadingTags = true;
    })
    .addCase(getTags.fulfilled, (state, action) => {
      state.tags = action.payload.data;
      state.loadingTags = false;
    })
    .addCase(getTags.rejected, (state, action) => {
      state.errorCertificate = action.payload;
      state.loadingTags = false;
    })
    .addCase(getTypes.pending, (state) => {
      state.loadingTypes = true;
    })
    .addCase(getTypes.fulfilled, (state, action) => {
      state.types = action.payload.data;
      state.loadingTypes = false;
    })
    .addCase(getTypes.rejected, (state, action) => {
      state.loadingTypes = false;
      state.errorCertificate = action.payload;
    });
  }
})

export default certificateSlice.reducer