import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  species: [
    {
      id: 1,
      ticker:"AL29",
      name:"Bono AL29",
      type:"Soberano",
      pro:true,
      lite:true,
      enabled:true,
      buy:true,
      sellbled:true,
      currency: 'USD',
      enterpriseDetails:"Detalles de la empresa Bono AL30",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/AL30",
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 2,
      ticker:"ALUA",
      name:"Bono AL30",
      type:"Soberano",
      pro:true,
      lite:true,
      enabled:true,
      buy:true,
      sellbled:true,
      currency: 'USD',
      enterpriseDetails:"Detalles de la empresa Bono AL30",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/AL30",
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 3,
      ticker:"GD32",
      name:"Bono GD32",
      type:"Soberano",
      pro:true,
      lite:true,
      enabled:true,
      buy:true,
      sellbled:true,
      currency: 'USD',
      enterpriseDetails:"Detalles de la empresa Bono GD32",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/GD32",
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 4,
      ticker:"AL30",
      name:"Bono AL30",
      type:"Soberano",
      pro:false,
      lite:true,
      enabled:true,
      buy:true,
      sellbled:true,
      currency: 'USD',
      enterpriseDetails:"Detalles de la empresa Bono AL30",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/AL30",
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 5,
      ticker:"GD32",
      name:"Bono GD32",
      type:"Provincial",
      pro:false,
      lite:true,
      enabled:false,
      buy:true,
      sellbled:false,
      currency: 'USD',
      enterpriseDetails:"Detalles de la empresa Bono GD32",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/GD32",
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 6,
      ticker:"MA30",
      name:"Bono Macro",
      type:"Corporativo",
      lite:false,
      pro:true,
      buy:false,
      sell:true,
      enabled:true,
      currency: 'ARS',
      enterpriseDetails:"Detalles de la empresa Bono Macro",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/Macro",
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 7,
      ticker:"AL35",
      name:"Bono AL35",
      type:"Corporativo",
      lite:false,
      pro:true,
      buy:false,
      sell:true,
      enabled:true,
      currency: 'USD',
      enterpriseDetails:"Detalles de la empresa Bono AL35",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/AL35",
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 8,
      ticker:"GD30",
      name:"Bono GD30",
      type:"Corporativo",
      lite:false,
      pro:true,
      buy:false,
      sell:true,
      enabled:true,
      currency: 'ARS',
      enterpriseDetails:"Detalles de la empresa Bono GD30",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/GD30",
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 9,
      ticker:"GD30",
      name:"Bono GD30",
      type:"Corporativo",
      lite:false,
      pro:true,
      buy:false,
      sell:true,
      enabled:true,
      currency: 'ARS',
      enterpriseDetails:"Detalles de la empresa Bono GD30",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/GD30",
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 10,
      ticker:"GD30",
      name:"Bono GD30",
      type:"Corporativo",
      lite:false,
      pro:true,
      buy:false,
      sell:true,
      enabled:true,
      currency: 'ARS',
      enterpriseDetails:"Detalles de la empresa Bono GD30",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/GD30",
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 11,
      ticker:"GD30",
      name:"Bono GD30",
      type:"Corporativo",
      lite:false,
      pro:true,
      buy:false,
      sell:true,
      enabled:true,
      currency: 'ARS',
      enterpriseDetails:"Detalles de la empresa Bono GD30",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/GD30",
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 12,
      ticker:"GD30",
      name:"Bono GD30",
      type:"Corporativo",
      lite:false,
      pro:true,
      buy:false,
      sell:true,
      enabled:true,
      currency: 'ARS',
      enterpriseDetails:"Detalles de la empresa Bono GD30",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/GD30",
      lastModification: '2021-04-07 20:08:23',
    }
    ,
    {
      id: 13,
      ticker:"GD31",
      name:"Bono GD31",
      type:"Corporativo",
      lite:false,
      pro:true,
      buy:false,
      sell:true,
      enabled:true,
      currency: 'ARS',
      enterpriseDetails:"Detalles de la empresa Bono GD31",
      paper:21.0407,
      iconURL:"http://URL/del/Icono/para/el/Bono/GD31",
      lastModification: '2021-04-07 20:08:23',
    }
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/bond/species').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.species.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter;
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.species,
      total: filteredData.length,
      species: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/bonds\/\d+/).reply(config => {
  // // Get event id from URL
  const specieId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const specieIndex = data.species.findIndex(e => e.id === specieId)
  const responseData = {
      ...data.species[specieIndex],
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
// mock.onDelete('/funds/delete').reply(config => {
//   // Get specie id from URL
//   let specieId = config.id

//   // Convert Id to number
//   specieId = Number(specieId)

//   const specieIndex = data.species.findIndex(t => t.id === specieId)
//   data.species.splice(specieIndex, 1)

//   return [200]
// })