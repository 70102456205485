// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getSpecificFund = createAsyncThunk('fund/getSpecificFund', async (ticker, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/fund/${ticker}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export const resetSpecificFund = createAsyncThunk('fund/resetSpecificFund', async params => {
  return {
    params,
    data: {},
  }
})

export const getTags = createAsyncThunk('tags/getTags', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/markettags?page=1&itemsPage=10`, params);
    return {
      params,
      data: response.data.list,
    };
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export const getTypes = createAsyncThunk('fund/getTypes', async params => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/fundcategories?page=1&itemsPage=10`, params)
  return {
    params,
    data: response.data.list,
  }
})

export const putFund = createAsyncThunk('fund/putFund', async params => {
  const response = await axios.put(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/fund`, params)
  return response
})

export const disableFund = createAsyncThunk('fund/disableFund', async ticker => {
  const response = await axios.delete(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/fund/${ticker}`)
  return response
})

export const getFunds = createAsyncThunk('fund/getFunds', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/funds`, {
      params: { page: params.page, itemsPage: params.itemsPerPage }
    });
    return {
      params,
      data: response.data,
      totalRecords: response.data.numberRecordsTotal,
      totalPages: response.data.numberOfPages,
      nextPage: response.data.nextPage,
      previousPage: response.data.previousPage 
    };
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});


export const getRecomended = createAsyncThunk('fund/getRecomended', async params => {
  const response = await axios.get('/funds/recomended', params)
  return {
    params,
    data: response.data.species,
  }
})

export const getSpecificRecomended = createAsyncThunk('fund/getSpecificRecomended', async params => {
  const response = await axios.get(`/funds/recomended/${params.id}`, params)
  return {
    params,
    data: response.data,
  }
})

export const resetSpecificRecomended = createAsyncThunk('fund/resetSpecificRecomended', async params => {
  return {
    params,
    data:null,
  }
})

export const deleteRecomended = createAsyncThunk('fund/deleteRecomended' , async id => {
  const response = await axios.delete(`/funds/recomended/delete/${id}`)
  return {
    data:response.data,
  }
})


export const deleteSpecie = createAsyncThunk('fund/deleteSpecie', async (id, { dispatch, getState }) => {
  await axios.delete('/funds/delete', { id })
  //await dispatch(getAddresses(getState().specie.params))
  return id
})

export const fundSlice = createSlice({
  name: 'funds',
  initialState: {
    items: [],
    totalPages: 1,
    currentPage: 1,
    fundsParams: {},

    fund:{},
    tags:[],
    types:[],

    loadingFund: false,
    errorFund: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getFunds.pending, (state) => {
      state.loadingFund = true;
    })
    .addCase(getFunds.fulfilled, (state, action) => {
      state.items = action.payload.data.list;
      state.totalRecords = action.payload.data.numberRecordsTotal;
      state.totalPages = action.payload.data.numberOfPages;
      state.currentPage = action.payload.params.page;
      state.fundsParams = action.payload.params;
      state.loadingFund = false;
    })
    .addCase(getFunds.rejected, (state, action) => {
      state.loadingFund = false;
      state.error = action.payload;
    })
    .addCase(getSpecificFund.pending, (state) => {
      state.loadingFund = true;
    })
    .addCase(getSpecificFund.fulfilled, (state, action) => {
      state.fund = action.payload
      state.loadingFund = false;
    })
    .addCase(getSpecificFund.rejected, (state, action) => {
      state.loadingFund = false;
      state.error = action.payload;
    })
    .addCase(resetSpecificFund.fulfilled, (state, action) => {
      state.fund = action.payload;
      state.totalPages = 1;
      state.currentPage = 1;
    })
    .addCase(getTags.pending, (state) => {
      state.loadingFund = true;
    })
    .addCase(getTags.fulfilled, (state, action) => {
      state.tags = action.payload.data
      state.loadingFund = false;
    })
    .addCase(getTags.rejected, (state, action) => {
      state.loadingFund = false;
      state.error = action.payload;
    })
    .addCase(getTypes.pending, (state) => {
      state.loadingFund = true;
    })
    .addCase(getTypes.fulfilled, (state, action) => {
      state.types = action.payload.data
      state.loadingFund = false;
    })
    .addCase(getTypes.rejected, (state, action) => {
      state.loadingFund = false;
      state.error = action.payload;
    });
  }
})

export default fundSlice.reducer