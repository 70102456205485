import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  species: [
    {
      id: 1,
      name:"AdCap Pesos Plus",
      class:"B",
      currency:"PESOS",
      subscribe:true,
      rescue:false,
      risk:"MODERADO",
      pro:true,
      lite:false,
      management:"Investis Asset Managment",
      enabled:0,
    },
    {
      id: 2,
      name:"AdCap Pesos Plus",
      class:"A",
      currency:"DOLARES",
      subscribe:true,
      rescue:false,
      risk:"AGRESIVO",
      pro:false,
      lite:true,
      management:"AdCap Asset Managment",
      enabled:1,
    },
    {
      id: 3,
      name:"AdCap Securities",
      class:"B",
      currency:"PESOS",
      subscribe:true,
      rescue:false,
      risk:"MODERADO",
      pro:true,
      lite:true,
      management:"Investis Asset Managment",
      enabled:0,
    },
    {
      id: 4,
      name:"AdCap Securities",
      class:"A",
      currency:"PESOS",
      subscribe:true,
      rescue:false,
      risk:"CONSERVADOR",
      pro:false,
      lite:true,
      management:"AdCap Asset Managment",
      enabled:0,
    },
    {
      id: 5,
      name:"AdCap Pesos Plus",
      class:"B",
      currency:"DOLARES",
      subscribe:true,
      rescue:false,
      risk:"CONSERVADOR",
      pro:true,
      lite:false,
      management:"AdCap Asset Managment",
      enabled:1,
    },
    {
      id: 6,
      name:"AdCap Pesos Plus",
      class:"A",
      currency:"PESOS",
      subscribe:true,
      rescue:false,
      risk:"MODERADO",
      pro:false,
      lite:true,
      management:"Investis Asset Managment",
      enabled:0,
    },
    {
      id: 7,
      name:"AdCap Securities",
      class:"B",
      currency:"PESOS",
      subscribe:true,
      rescue:false,
      risk:"AGRESIVO",
      pro:true,
      lite:false,
      management:"Investis Asset Managment",
      enabled:1,
    },
    {
      id: 8,
      name:"AdCap Securities",
      class:"B",
      currency:"DOLARES",
      subscribe:true,
      rescue:false,
      risk:"AGRESIVO",
      pro:true,
      lite:false,
      management:"AdCap Asset Managment",
      enabled:0,
    },
    {
      id: 9,
      name:"AdCap Securities",
      class:"B",
      currency:"DOLARES",
      subscribe:true,
      rescue:false,
      risk:"MODERADO",
      pro:false,
      lite:true,
      management:"Investis Asset Managment",
      enabled:1,
    },
    {
      id: 10,
      name:"AdCap Pesos Plus",
      class:"A",
      currency:"DOLARES",
      subscribe:true,
      rescue:false,
      risk:"MODERADO",
      pro:false,
      lite:false,
      management:"Investis Asset Managment",
      enabled:0,
    },
    {
      id: 11,
      name:"AdCap Securities",
      class:"C",
      currency:"DOLARES",
      subscribe:true,
      rescue:false,
      risk:"CONSERVADOR",
      pro:false,
      lite:false,
      management:"AdCap Asset Managment",
      enabled:0,
    },
    {
      id: 12,
      name:"AdCap Pesos Plus",
      class:"B",
      currency:"DOLARES",
      subscribe:true,
      rescue:false,
      risk:"AGRESIVO",
      pro:false,
      lite:false,
      management:"AdCap Asset Managment",
      enabled:0,
    },
    {
      id: 13,
      name:"AdCap Securities",
      class:"C",
      currency:"PESOS",
      subscribe:true,
      rescue:false,
      risk:"AGRESIVO",
      pro:true,
      lite:true,
      management:"AdCap Asset Managment",
      enabled:1,
    }
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/funds/species').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.species.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter;
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.species,
      total: filteredData.length,
      species: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/funds\/\d+/).reply(config => {
  // // Get event id from URL
  const specieId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const specieIndex = data.species.findIndex(e => e.id === specieId)
  const responseData = {
      ...data.species[specieIndex],
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete('/funds/delete').reply(config => {
  // Get specie id from URL
  let specieId = config.id

  // Convert Id to number
  specieId = Number(specieId)

  const specieIndex = data.species.findIndex(t => t.id === specieId)
  data.species.splice(specieIndex, 1)

  return [200]
})