// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// -- GET Unique Referral (Filtro personalizado)
export const getUniqueReferral = createAsyncThunk('referral/uniqueReferral', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/get_promo_code_by`, { params });
    return {
      data: response.data
    }
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
})

// -- GET All Referrals (Traer todos los códigos de referidos)
export const getReferrals = createAsyncThunk('referrals/allReferrals', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/promo_codes`);
    return {
      data: response.data.list
    }
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
})

// -- POST Create Referral (Crear un código referido)
export const createReferral = createAsyncThunk('referral/createReferral', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/create_promo_code`, params);
    return {
      data: response.data
    }
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
})

// -- PATCH Edit Referral (Editar un código referido)
export const editReferral = createAsyncThunk('referral/editReferral', async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/edit_promo_code`, data, {
      params: {
        promo_code_id: id,
      }
    });
    return {
      data: response.data
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});


export const referralsSlice = createSlice({
  name: 'referrals',
  initialState: {
    referrals: [],
    referral: {},
    referralsLoading: true,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      // getReferrals
      .addCase(getReferrals.fulfilled, (state, action) => {
        state.referrals = action.payload.data;
        state.referralsLoading = false;
      })
      .addCase(getReferrals.pending, (state) => {
        state.referralsLoading = true;
        state.error = null;
      })
      .addCase(getReferrals.rejected, (state, action) => {
        state.referralsLoading = false;
        state.error = action.payload
      })
      
      // getUniqueReferral
      .addCase(getUniqueReferral.fulfilled, (state, action) => {
        state.referral = action.payload.data;
        state.referralsLoading = false;
      })
      .addCase(getUniqueReferral.pending, (state) => {
        state.referralsLoading = true;
        state.error = null;
      })
      .addCase(getUniqueReferral.rejected, (state, action) => {
        state.referralsLoading = false;
        state.error = action.payload
      })
      
      // CreateReferral
      .addCase(createReferral.fulfilled, (state, action) => {
        state.referrals.push(action.payload.data);
        state.referralsLoading = false;
      })
      .addCase(createReferral.pending, (state) => {
        state.referralsLoading = true;
        state.error = null; 
      })
      .addCase(createReferral.rejected, (state, action) => {
        state.referralsLoading = false;
        state.error = action.payload
      })
      
      // EditReferral
      .addCase(editReferral.fulfilled, (state, action) => {
        const index = state.referrals.findIndex(referral => referral.id === action.payload.data.id);
        if (index !== -1) {
          state.referrals[index] = action.payload.data;
        }
        state.referralsLoading = false;
      })
      .addCase(editReferral.pending, (state) => {
        state.referralsLoading = true;
        state.error = null;
      })
      .addCase(editReferral.rejected, (state, action) => {
        state.referralsLoading = false;
        state.error = action.payload
      })
  }
})

export default referralsSlice.reducer;
