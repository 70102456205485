// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import client from '@src/views/clients/store'
import funds from '@src/views/funds/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import certificate from '@src/views/stock-exchange/certificates/store'
import equity from '@src/views/stock-exchange/equities/store'
import bond from '@src/views/stock-exchange/bonds/store'
import pack from '@src/views/stock-exchange/packs/store'
import reasons from '@src/views/saving-module/saving-reasons/store'
import goals from '@src/views/saving-module/client-goals/store'
import user from '../views/settings/user-management/store'
import role from '../views/settings/role-management/store'
import contentCashFlow from '../views/cashflow/store'
import holidaysSlice from '../views/holidays/store'
import trendsSlice from '../views/trends/store'
import packSlice from '../views/stock-exchange/packs/store'
import referralsSlice from '../views/referrals/store'
import contentFiles from '../views/conciliaciones/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  client,
  funds,
  certificate,
  equity,
  bond,
  pack,
  reasons,
  goals,
  calendar,
  ecommerce,
  dataTables,
  user,
  role,
  permissions,
  contentCashFlow,
  holidaysSlice,
  trendsSlice,
  packSlice,
  referralsSlice,
  contentFiles
}

export default rootReducer