// ** JWT Service Import
import JwtService from './jwtService'

// ** Export Service as useJwt
export default function useJwt(jwtOverrideConfig) {
  const jwt = new JwtService(jwtOverrideConfig)

  const getToken = () => {
    return jwt.getToken()
  }

  return {
    jwt,
    getToken
  }
}
