import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
    onboardingList:[
        {
            uuid: 4234234,
            email:'mamani@grupomaenzsas.com.ar',
            description:'GRUPO MASENZ SAS',
            cuit:'18553977586',
            onboarding_status:'EN PROCESO',
            onboarding_type:' Juridica',
            onboarding_step:'proceso',
            onboarding_date:'2021-10-28',
            actions:'Accion',
        },
        {
            uuid: 4237634,
            email:'secondbusines@usermail2.com.ar',
            description:'GRUPO EMPRESA B',
            cuit:'38746002648',
            onboarding_status:'RECHAZADO',
            onboarding_type:'Cuenta Persona Física',
            onboarding_step:'',
            onboarding_date:'2021-11-06',
            actions:'Accion',
        },
        {
            uuid: 429878978,
            email:'userthird@domainexample.com.ar',
            description:'EMPRESA TRES',
            cuit:'30877599847',
            onboarding_status:'PENDIENTE',
            onboarding_type:'Cuenta Persona Juridica',
            onboarding_step:'Documentacion Empresa',
            onboarding_date:'2021-08-24',
            actions:'Accion',
        },
        {
            uuid: 56456457,
            email:'cuarto@grupocuarto.com.ar',
            description:'CUARTA EMPRESA',
            cuit:'3099846018476',
            onboarding_status:'PENDIENTE',
            onboarding_type:'Cuenta Persona Juridica',
            onboarding_step:'',
            onboarding_date:'2021-04-12',
            actions:'Accion',
        },
        {
            uuid: 42089087,
            email:'panini@testdomaint.com.ar',
            description:'EMPRESA CINCO',
            cuit:'305973094756',
            onboarding_status:'RECHAZADO',
            onboarding_type:'Cuenta Persona Fisica',
            onboarding_step:'Documentacion Empresa',
            onboarding_date:'2022-02-14',
            actions:'Accion',
        },
    ]
}

// mock.onGet('api/clients/onboarding').reply(200,data.onboardingList)

mock.onGet('/api/clients/onboarding').reply(200, data.onboardingList)