import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  species: [
    {
      id: 1,
      ticker:"AGRO",
      name:"Agrometal SAI",
      index:"S&P Merval",
      industry:"Materiales Basicos",
      risk:"Arriesgado",
      view:2,
      enabled:true,
      lite:false,
      pro:true,
      currency: 'USD',
      operations:'AMBOS',
      lastModification: '2021-04-07 20:08:23'
    },
    {
      id: 2,
      ticker:"ALUA",
      name:"Aluar Alumninio Argentino SAIC",
      index:"S&P Merval",
      industry:"Materiales básicos",
      risk:"Conservador",
      view:1,
      enabled:true,
      lite:false,
      operations:'AMBOS',
      pro:true,
      currency: 'USD',
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 3,
      ticker:"AUSO",
      name:"Autopistas del sol",
      index:"S&P Merval",
      industry:"Materiales básicos",
      risk:"Moderado",
      view:1,
      enabled:true,
      lite:false,
      operations:'AMBOS',
      pro:true,
      currency: 'USD',
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 4,
      ticker:"BBAR",
      name:"Brubank",
      index:"S&P Merval",
      industry:"Servicios",
      risk:"Conservador",
      view:2,
      enabled:true,
      lite:false,
      operations:'AMBOS',
      pro:true,
      currency: 'USD',
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 5,
      ticker:"BHIP",
      name:"Banco Hipotecario",
      index:"S&P Merval",
      industry:"Materiales básicos",
      risk:"Arriesgado",
      view:1,
      enabled:false,
      lite:true,
      pro:true,
      operations:'AMBOS',
      currency: 'USD',
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 6,
      ticker:"BMA",
      name:"Banco Macro",
      index:"Panel General",
      industry:"Servicios",
      risk:"Conservador",
      view:2,
      enabled:true,
      lite:true,
      pro:false,
      operations:'AMBOS',
      currency: 'ARS',
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 7,
      ticker:"BOLT",
      name:"Boldt SA",
      index:"Panel General",
      industry:"Tecnología",
      risk:"Arriesgado",
      view:0,
      enabled:true,
      lite:false,
      pro:true,
      operations:'AMBOS',
      currency: 'USD',
      lastModification: '2021-04-07 20:08:23',
    },
    {
      id: 8,
      ticker:"BPAT",
      name:"Banco Patagonia SA",
      index:"Panel General",
      industry:"Tecnología",
      risk:"Moderado",
      view:2,
      enabled:true,
      lite:false,
      pro:true,
      operations:'AMBOS',
      currency: 'ARS',
      lastModification: '2021-04-07 20:08:23',
    },
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/equity/species').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.species.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter;
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.species,
      total: filteredData.length,
      species: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/equities\/\d+/).reply(config => {
  // // Get event id from URL
  const specieId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const specieIndex = data.species.findIndex(e => e.id === specieId)
  const responseData = {
      ...data.species[specieIndex],
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
// mock.onDelete('/funds/delete').reply(config => {
//   // Get specie id from URL
//   let specieId = config.id

//   // Convert Id to number
//   specieId = Number(specieId)

//   const specieIndex = data.species.findIndex(t => t.id === specieId)
//   data.species.splice(specieIndex, 1)

//   return [200]
// })