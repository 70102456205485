import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  reasons: [
    {
      id: 1,
      enabled:true,
      uniqueCode:"FONDO DE EMERGENCIA",
      description:"Fondo de emergencia",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 2,
      enabled:true,
      uniqueCode:"Viaje",
      description:"VIAJE",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 3,
      enabled:true,
      uniqueCode:"Mudanza",
      description:"MUDANZA",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 4,
      enabled:true,
      uniqueCode:"Viaje",
      description:"VIAJE",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 5,
      enabled:false,
      uniqueCode:"Mudanza",
      description:"MUDANZA",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 6,
      enabled:true,
      uniqueCode:"Vehiculo",
      description:"VEHICULO",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 7,
      enabled:true,
      uniqueCode:"Festejar",
      description:"FESTEJAR",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 8,
      enabled:true,
      uniqueCode:"Tecnologia",
      description:"TECNOLOGIA",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 9,
      enabled:true,
      uniqueCode:"Tecnologia",
      description:"TECNOLOGIA",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 10,
      enabled:true,
      uniqueCode:"Tecnologia",
      description:"TECNOLOGIA",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 11,
      enabled:true,
      uniqueCode:"Tecnologia",
      description:"TECNOLOGIA",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 12,
      enabled:true,
      uniqueCode:"Tecnologia",
      description:"TECNOLOGIA",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    },
    {
      id: 13,
      enabled:true,
      uniqueCode:"Regalo",
      description:"REGALO",
      lastModification:"28/05/2021 23:38:24",
      headIcon:"http://url/Icono de cabecera/icon",
      cellIcon:"http://url/Icono de celda/icon",
      icon:"http://url/Icono/icon",
    }
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/saving/reasons').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.reasons.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter;
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.reasons,
      total: filteredData.length,
      reasons: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/saving\/reason\/\d+/).reply(config => {
  // // Get event id from URL
  const reasonId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const reasonIndex = data.reasons.findIndex(e => e.id === reasonId)
  const responseData = {
      ...data.reasons[reasonIndex],
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
// mock.onDelete('/funds/delete').reply(config => {
//   // Get reason id from URL
//   let reasonId = config.id

//   // Convert Id to number
//   reasonId = Number(reasonId)

//   const reasonIndex = data.reasons.findIndex(t => t.id === reasonId)
//   data.reasons.splice(reasonIndex, 1)

//   return [200]
// })