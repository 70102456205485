// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Thunk para obtener todos los packs
export const getPacks = createAsyncThunk('pack/getPack', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/packs`, { params: { page: params.page, itemsPage: params.itemsPage } })
    return {
      params,
      data: response.data.list,
      totalRecords: response.data.numberRecordsTotal,
      totalPages: response.data.numberOfPages,
      nextPage: response.data.nextPage,
      previousPage: response.data.previousPage 
    }
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message)
  }
})

// ** Thunk para obtener un pack por su ticker
export const getPackByTicker = createAsyncThunk('pack/getPackByTicker', async (ticker, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/pack/${ticker}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// ** Thunk para actualizar un pack
export const updatePack = createAsyncThunk('pack/updatePack', async (packData, { rejectWithValue }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/pack`, packData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});


// ** Slice de Redux
export const packSlice = createSlice({
  name: 'pack',
  initialState: {
    loadingPacks: false,
    allPacks: [],
    totalRecords: 0,
    totalPages: 0,
    nextPage: null,
    previousPage: null,
    errorPacks: null,
    selectedPack: null,
    loadingPack: false,
    errorPack: null,
    updatingPack: false,
    updatePackError: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPacks.pending, (state) => {
        state.loadingPacks = true
        state.errorPacks = null
      })
      .addCase(getPacks.fulfilled, (state, action) => {
        state.loadingPacks = false
        state.allPacks = action.payload.data
        state.totalRecords = action.payload.totalRecords
        state.totalPages = action.payload.totalPages
        state.nextPage = action.payload.nextPage
        state.previousPage = action.payload.previousPage
        state.errorPacks = null
      })
      .addCase(getPacks.rejected, (state, action) => {
        state.loadingPacks = false
        state.errorPacks = action.payload
      })
      .addCase(getPackByTicker.pending, (state) => {
        state.loadingPack = true;
        state.errorPack = null;
      })
      .addCase(getPackByTicker.fulfilled, (state, action) => {
        state.loadingPack = false;
        state.selectedPack = action.payload;
        state.errorPack = null;
      })
      .addCase(getPackByTicker.rejected, (state, action) => {
        state.loadingPack = false;
        state.errorPack = action.payload;
      })
      .addCase(updatePack.pending, (state) => {
        state.updatingPack = true;
        state.updatePackError = null;
      })
      .addCase(updatePack.fulfilled, (state, action) => {
        state.updatingPack = false;
        state.selectedPack = action.payload;
        state.updatePackError = null;
      })
      .addCase(updatePack.rejected, (state, action) => {
        state.updatingPack = false;
        state.updatePackError = action.payload;
      });
  }
})

export default packSlice.reducer
