import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
    onboardingUserInformation:[
        {
            id:1,
            cuitCUIL:'18553977586',
            loadedInformation:[
                {name:'Nombre',value:'Ruben',status:'Cargado'},
                {name:'Apellido',value:'Mamani',status:'Cargado'},
                {name:'Fecha Nac.',value:'1986-12-05',status:'Cargado'},
                {name:'Lugar Nacimiento',value:'',status:'Cargado'},
                {name:'Codigo de Area',value:'011',status:'Cargado'},
                {name:'Telefono',value:'48856987',status:'Cargado'},
                {name:'Genero',value:'',status:'Pendiente de Carga'},
                {name:'Es Extrangero',value:'',status:'Cargado'},
                {name:'Nacionalidad',value:'Argentina',status:'Cargado'},
                {name:'Calle',value:'Arenales',status:'Cargado'},
                {name:'Numero',value:'2565',status:'Cargado'},
                {name:'Localidad',value:'Acassuso',status:'Cargado'},
                {name:'Codigo Postal',value:'B7600',status:'Cargado'},
                {name:'Provincia',value:'Buenos Aires',status:'Cargado'},
                {name:'Pais',value:'Argentina',status:'Cargado'},
                {name:'Codigo de referido',value:'',status:'Cargado'},
            ],
            legalInformation:[
                {name:'Actividad AFIP',value:'Servicios personales n.c.p.',status:'Cargado'},
                {name:'Profesíon',value:'Servicios personales n.c.p.',status:'Cargado'},
                {name:'Inscripcion Ganancias',value:'Exento',status:'Cargado'},
                {name:'Condicion IVA',value:'Exento',status:'Cargado'},
                {name:'Ingresos Brutos',value:'',status:'Cargado'},
                {name:'PEP',value:'No',status:'Cargado'},
                {name:'PEP descripcion',value:'',status:'Pendiente de Carga'},
                {name:'Sujeto Obligado',value:'No',status:'Cargado'},
                {name:'FATCA',value:'No',status:'Cargado'},
                {name:'OCDE',value:'No',status:'Cargado'},
            ]
        },
        {
            id:2,
            cuitCUIL:'30877599847',
            loadedInformation:[
                {name:'Nombre',value:'Hector',status:'Cargado'},
                {name:'Apellido',value:'Gonzales',status:'Cargado'},
                {name:'Fecha Nac.',value:'1976-12-05',status:'Cargado'},
                {name:'Lugar Nacimiento',value:'',status:'Cargado'},
                {name:'Codigo de Area',value:'011',status:'Cargado'},
                {name:'Telefono',value:'48856987',status:'Cargado'},
                {name:'Genero',value:'',status:'Pendiente de Carga'},
                {name:'Es Extrangero',value:'',status:'Cargado'},
                {name:'Nacionalidad',value:'Argentina',status:'Cargado'},
                {name:'Calle',value:'Arenales',status:'Cargado'},
                {name:'Numero',value:'2565',status:'Cargado'},
                {name:'Localidad',value:'Acassuso',status:'Cargado'},
                {name:'Codigo Postal',value:'B7600',status:'Cargado'},
                {name:'Provincia',value:'Buenos Aires',status:'Cargado'},
                {name:'Pais',value:'Argentina',status:'Cargado'},
                {name:'Codigo de referido',value:'',status:'Cargado'},
            ],
            legalInformation:[
                {name:'Actividad AFIP',value:'Servicios personales n.c.p.',status:'Cargado'},
                {name:'Profesíon',value:'Servicios personales n.c.p.',status:'Cargado'},
                {name:'Inscripcion Ganancias',value:'Exento',status:'Cargado'},
                {name:'Condicion IVA',value:'Exento',status:'Cargado'},
                {name:'Ingresos Brutos',value:'',status:'Cargado'},
                {name:'PEP',value:'No',status:'Cargado'},
                {name:'PEP descripcion',value:'',status:'Pendiente de Carga'},
                {name:'Sujeto Obligado',value:'No',status:'Cargado'},
                {name:'FATCA',value:'No',status:'Cargado'},
                {name:'OCDE',value:'No',status:'Cargado'},
            ]
        },
    ]
}

mock.onGet('/api/clients/loadedInformation').reply(200, data.onboardingUserInformation)

// ------------------------------------------------
// GET: Return Single User Information
// ------------------------------------------------
            // /\/funds\/recomended\/delete\/\d+/
mock.onGet(/\/api\/clients\/loadedInformation\/\d+/).reply(config => {
    // // Get event id from URL
    const clientId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))
  
    const userIndex = data.onboardingUserInformation.findIndex(e => Number(e.cuitCUIL) === clientId)
    const responseData = data.onboardingUserInformation[userIndex]
    

    return [200, responseData]
  })
