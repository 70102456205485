import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  invoices: [
    {
      id: 4987,
      country: 'Paid',
      province: 'Software Development',
      locality: 'Loca-paid-lity',
      postalCode: 'CP-132019',
      street: 'Street 13 Dec 2019',
      number: 3428,
      floor: 3,
      flat: 'A'
    }
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/apps/society-addresses/invoices').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { perPage = 10, page = 1 } = config

  return [
    200,
    {
      allData: data.invoices,
      total: data.invoices.length,
      invoices: data.invoices.length <= perPage ? data.invoices : paginateArray(data.invoices, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/invoice\/invoices\/\d+/).reply(config => {
  // // Get event id from URL
  const invoiceId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const invoiceIndex = data.invoices.findIndex(e => e.id === invoiceId)
  const responseData = {
    invoice: data.invoices[invoiceIndex],
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905'
    }
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete('/apps/invoice/delete').reply(config => {
  // Get invoice id from URL
  let invoiceId = config.id

  // Convert Id to number
  invoiceId = Number(invoiceId)

  const invoiceIndex = data.invoices.findIndex(t => t.id === invoiceId)
  data.invoices.splice(invoiceIndex, 1)

  return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/api/invoice/clients').reply(() => {
  const clients = data.invoices.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})