import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  goals: [
    {
      id: 1,
      status:"Pausada",
      account:35138,
      cuit:"27217535138",
      creationDate:"08/04/2022",
      goalType:"Inversión única",
      currency:"Pesos",
      goal:"Ahorrar",
      amountShare:700,
      collectedShares:"3/1",
    },
    {
      id: 2,
      status:"Pausada",
      account:35238,
      cuit:"27217535238",
      creationDate:"08/04/2022",
      goalType:"Inversión única",
      currency:"Pesos",
      goal:"Ahorrar",
      amountShare:200,
      collectedShares:"3/2",
    },
    {
      id: 3,
      status:"Pausada",
      account:35338,
      cuit:"27217535338",
      creationDate:"08/04/2022",
      goalType:"Inversión única",
      currency:"Pesos",
      goal:"Festejar",
      amountShare:370,
      collectedShares:"3/3",
    },
    {
      id: 4,
      status:"Cancelada",
      account:35348,
      cuit:"27217535348",
      creationDate:"08/04/2022",
      goalType:"Cuota fija",
      currency:"Pesos",
      goal:"Fondo de Emergencia",
      amountShare:470,
      collectedShares:"3/4",
    },
    {
      id: 5,
      status:"Pausada",
      account:35538,
      cuit:"27217535538",
      creationDate:"08/04/2022",
      goalType:"Cuota fija",
      currency:"Pesos",
      goal:"Festejar",
      amountShare:990,
      collectedShares:"3/5",
    },
    {
      id: 6,
      status:"Cancelada",
      account:35386,
      cuit:"27217535386",
      creationDate:"08/04/2022",
      goalType:"Inversión única",
      currency:"Pesos",
      goal:"Fondo de Emergencia",
      amountShare:480,
      collectedShares:"3/6",
    },
    {
      id: 7,
      status:"Cancelada",
      account:35738,
      cuit:"27217535738",
      creationDate:"08/04/2022",
      goalType:"Inversión única",
      currency:"Pesos",
      goal:"Fondo de Emergencia",
      amountShare:700,
      collectedShares:"3/7",
    },
    {
      id: 8,
      status:"En curso",
      account:35838,
      cuit:"27217535838",
      creationDate:"08/04/2022",
      goalType:"Inversión única",
      currency:"Pesos",
      goal:"Festejar",
      amountShare:800,
      collectedShares:"3/8",
    },
    {
      id: 9,
      status:"Cancelada",
      account:39538,
      cuit:"27217539538",
      creationDate:"08/04/2022",
      goalType:"Inversión única",
      currency:"Pesos",
      goal:"Festejar",
      amountShare:900,
      collectedShares:"3/9",
    },
    {
      id: 10,
      status:"En curso",
      account:35308,
      cuit:"27217535308",
      creationDate:"08/04/2022",
      goalType:"Inversión única",
      currency:"Pesos",
      goal:"Vehículo",
      amountShare:1000,
      collectedShares:"3/5",
    },
    {
      id: 11,
      status:"Cancelada",
      account:32538,
      cuit:"27217532538",
      creationDate:"08/04/2022",
      goalType:"Inversión única",
      currency:"Pesos",
      goal:"Festejar",
      amountShare:1100,
      collectedShares:"3/7",
    },
    {
      id: 12,
      status:"En curso",
      account:33538,
      cuit:"27217533538",
      creationDate:"08/04/2022",
      goalType:"Inversión única",
      currency:"Pesos",
      goal:"Vehículo",
      amountShare:1200,
      collectedShares:"3/1",
    },
    {
      id: 13,
      status:"En curso",
      account:43538,
      cuit:"27217543538",
      creationDate:"08/04/2022",
      goalType:"Inversión única",
      currency:"Pesos",
      goal:"Vehículo",
      amountShare:1300,
      collectedShares:"4/1",
    }
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/client/goals').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.goals.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter;
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.goals,
      total: filteredData.length,
      goals: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/client\/goal\/\d+/).reply(config => {
  // // Get event id from URL
  const goalId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const goalIndex = data.goals.findIndex(e => e.id === goalId)
  const responseData = {
      ...data.goals[goalIndex],
      name:"FABIANA AILEN REYNOSO ",
      term:"3 meses",
      progress:"0",
      savedTotal:"0",
      savingGoal:"31836.27",
      goalData: [
        {
          share:1,
          expirationDate:"24/03/2022",
          amount:15000,
          status:"Pendiente"
        },
        {
          share:2,
          expirationDate:"24/04/2022",
          amount:10050,
          status:"Pendiente"
        },
        {
          share:3,
          expirationDate:"24/05/2022",
          amount:50000,
          status:"Pendiente"
        }
      ]
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
// mock.onDelete('/funds/delete').reply(config => {
//   // Get goal id from URL
//   let goalId = config.id

//   // Convert Id to number
//   goalId = Number(goalId)

//   const goalIndex = data.goals.findIndex(t => t.id === goalId)
//   data.goals.splice(goalIndex, 1)

//   return [200]
// })