// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';

export const getTrends = createAsyncThunk('trends/getTrends', async () => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/instruments/trends`);
  return response.data;
});

export const addTrend = createAsyncThunk('trends/addTrend', async (trendData) => {
  const response = await axios.post(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/instruments/trend`, trendData);
  return response.data;
});

export const updateTrend = createAsyncThunk('trends/updateTrend', async (trendData) => {
  const response = await axios.put(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/instruments/trend`, trendData);
  return response.data;
});

export const deleteTrend = createAsyncThunk('trends/deleteTrend', async (trendData) => {
  const response = await axios.delete(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/instruments/trend`, { data: trendData });
  return response.data;
});

export const trendsSlice = createSlice({
  name: 'trends',
  initialState: {
    list: [],
    isLoading: false,
    isAdding: false,
    isUpdating: false,
    isDeleting: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
     // getTrends
     .addCase(getTrends.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTrends.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isLoading = false;
      })
      .addCase(getTrends.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      // updateTrend
      .addCase(updateTrend.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateTrend.fulfilled, (state, action) => {
        const index = state.list.trends.findIndex(trend => trend.ticker === action.payload.ticker);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        state.isUpdating = false;
      })
      .addCase(updateTrend.rejected, (state, action) => {
        state.isUpdating = false;
        state.error = action.error;
      })
  }
});
export default trendsSlice.reducer;