// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


// -- GET Manual Verification
export const getManualVerifications = createAsyncThunk('appInvoice/getManualVerifications', async () => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/manual_verification`)
  return {
    data: response.data
  }
})

// ------------------------


// -- POST Manual Verification
export const postmanualVerifications = createAsyncThunk('appInvoice/postManualVerifications', async (cashflow_id) => {
  const response = await axios.post(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/manual_verification/${cashflow_id}`);
  return {
    data: response.data
  }
})

// -- DELETE Manual Verification
export const deletemanualVerifications = createAsyncThunk('appInvoice/deleteManualVerifications', async (cashflow_id) => {
  const response = await axios.delete(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/manual_verification/${cashflow_id}`);
  return {
    data: response.data
  }
})


export const contentCashFlow = createSlice({
  name: 'contentCashFlow',
  initialState: {
    manualVerifications: [],
    manualVerificationsLoading: true,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getManualVerifications.fulfilled, (state, action) => {
        state.manualVerifications = action.payload.data.results
        state.manualVerificationsLoading = false
      })
      .addCase(getManualVerifications.pending, (state, action) => {
        console.log("Pending")
        state.manualVerificationsLoading = true
      })
  }
})

export default contentCashFlow.reducer