import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  invoices: [
    {
      uuid: 4990,
      fullName: 'RUBEN MAMANI',
      dni: '12345678',
      cuitCuil: '9876465321',
      refCode: '64-5321',
      accountNumber: "40-0-990",
      status: 'ACTIVO',
      created_at: '2020-08-25',
    },
    {
      uuid: 4387,
      fullName:'RAUL RODRIGUEZ',
      dni:'21346487',
      cuitCuil:'20213464873',
      refCode:'65-6548',
      accountNumber:"40-0-387",
      status:'ANULADO',
      created_at:'2021-07-12',
    },
    {
      uuid: 4376,
      fullName:'JOSE RODRIGUEZ',
      dni:'21346489',
      cuitCuil:'20613464873',
      refCode:'65-9548',
      accountNumber:"40-0-387",
      status:'BLOQUEADO',
      created_at:'2021-07-12',
    },
    {
      uuid: 4590,
      fullName: 'JUAN PEREZ',
      dni: '12345676',
      cuitCuil: '9876675321',
      refCode: '64-5671',
      accountNumber: "40-0-990",
      status: 'ACTIVO',
      created_at: '2022-07-25',
    },
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/apps/actived-clients/invoices').reply(config => {
  // eslint-disable-next-line object-curly-newline
  // const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  // const dataAsc = data.invoices.sort((a, b) => {
  //   if (a[sortColumn]) {
  //     return a[sortColumn] < b[sortColumn] ? -1 : 1
  //   } else {
  //     const splitColumn = sortColumn.split('.')
  //     const columnA = a[splitColumn[0]][splitColumn[1]]
  //     const columnB = b[splitColumn[0]][splitColumn[1]]
  //     return columnA < columnB ? -1 : 1
  //   }
  // })

  // const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  // const filteredData = dataToFilter;
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.invoices,
      // total: filteredData.length,
      // invoices: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
      invoices: data.invoices
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/clients\/list\/client\/\d+/).reply(config => {
  // // Get event id from URL
  const invoiceId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))
  const invoiceIndex = data.invoices.findIndex(e => e.uuid === invoiceId)
  const responseData = {
    invoice: data.invoices[invoiceIndex],
    lastModification: "28/05/2021 23:38:24",
    name: "AUGUSTO FERNANDEZ VILLA",
    identificationData: {
      CUIT: 20293796425,
      email: "augusto@btrader.io"
    },
    perfil: {
      risk: "Riesgo Medio",
      transactionalPerfil: "1,000,000.00",
      calculatedRisk: 44.650
    },
    personalData: {
      gender: "Masculino",
      birthDate: "1982-06-05",
      nacionality: "Argentina",
      bithPlace: "--",
      civicState: "Soltero/a",
      address: "Dr. de la Quintana 518 | Moreno B1744, Buenos Aires, Argentina",
      phoneNumber: 1162598839
    },
    blackList: {
      wasAHit: "No",
      lastExecution: "31/03/2020 05:51:59"
    },
    comment: 'Un comentario',
    accounts:
      { number: 5760 }
    ,
    investorTest: "Moderado",
    holders: [
      {
        name: "AUGUSTO",
        CUIT: 20293796425,
        role: "OWNER"
      }
    ],
    afip: {
      type: "Actividad",
      description: "Declaración Servicios de consultores en informática y suministros de programas de informática"
    },
    referred: {
      Código: "--",
      Nombre: "--",
      Descripción: "--"
    },
    taxInformation: [
      {
        type: "Pais residencia fiscal",
        declaration: "Argentina"
      },
      {
        type: "Ingresos Brutos",
        declaration: "--"
      },
      {
        type: "Condición IVA",
        declaration: "Responsable Inscripto"
      },
      {
        type: "Inscripción ganancias",
        declaration: "Inscripto"
      }
    ],
    PEP: [
      {
        type: "PEP",
        declaration: "No"
      },
      {
        type: "PEP Descripción",
        declaration: "--"
      }
    ],
    OCDE: [
      {
        type: "OCDE",
        declaration: "No"
      },
      {
        type: "OCDE - Pais de residencia fiscal principal",
        declaration: "--"
      },
      {
        type: "OCDE - Pais de residencia fiscal opcional",
        declaration: "--"
      },
      {
        type: "OCDE - NIT principal",
        declaration: "--"
      },
      {
        type: "OCDE - NIT opcional",
        declaration: "--"
      }
    ],
    FATCA: [
      {
        type: "FATCA",
        declaration: "No"
      },
      {
        type: "FATCA - SSN",
        declaration: "--"
      }
    ],
    obligedSubject: [
      { type: "Sujeto obligado", declaration: "--" },
      { type: "Tipo Sujeto obligado", declaration: "--" },
      { type: "Sujeto obligado fecha creación", declaration: "--" }
    ],
    documentation: [
      {
        type: "DNI",
        fileName: "F74F335E-6CED-4CEA-908D-98831C7507F8.jpg"
      },
      {
        type: "DNI",
        fileName: "188AF59E-2D67-4A74-A590-D2930CFAB982.jpg"
      },
      {
        type: "Selfie",
        fileName: "D1170C06-2D6F-4D74-8A7B-6C3B690EDC68.jpg"
      },
      {
        type: "Firma",
        fileName: "18D0B5E3-0882-4117-BC1F-B24211B5B6DB.png"
      },
      {
        type: "Firma",
        fileName: "signature.png"
      }
    ],
    signings: [
      { date: "04/10/2019 14:22:50", type: "General" },
      { date: "08/10/2019 13:33:25", type: "Fondos" },
      { date: "07/11/2019 15:51:06", type: "Fondos" },
      { date: "21/06/2020 17:38:04", type: "Fondos" },
      { date: "07/10/2020 20:24:37", type: "Fondos" },
      { date: "31/05/2021 15:54:32", type: "Fondos" },
      { date: "02/11/2021 15:49:32", type: "Monedas" },
      { date: "17/02/2022 01:48:14", type: "General" }
    ],
    answers: {
      perfil: "Conservador",
      calculatedValue: 15,
      executed: "26/07/2021 16:36:41",
      a1: 1,
      a2: 2,
      a3: 3,
      a4: 1,
      a5: 2,
      a6: 3,
    }
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete('/apps/invoice/delete').reply(config => {
  // Get invoice id from URL
  let invoiceId = config.id

  // Convert Id to number
  invoiceId = Number(invoiceId)

  const invoiceIndex = data.invoices.findIndex(t => t.id === invoiceId)
  data.invoices.splice(invoiceIndex, 1)

  return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/api/invoice/clients').reply(() => {
  const clients = data.invoices.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})





 // {
    //   id: 4987,
    //   client:'RAUL RODRIGUEZ',
    //   description:'Another description',
    //   account:"40-0-987",
    //   state:'TRUE',
    //   addingDate:'24 Apr 2020',
    // },
    // {
    //   id: 4910,
    //   client:'RUBEN MAMANI',
    //   description:'A description',
    //   account:"40-0-910",
    //   state:'FALSE',
    //   addingDate:'23 Apr 2019',
    // },
    // {
    //   id: 4985,
    //   client:'RAUL RODRIGUEZ',
    //   description:'Another description',
    //   account:"40-0-985",
    //   state:'TRUE',
    //   addingDate:'30 Apr 2020',
    // },
    // {
    //   id: 4290,
    //   client:'RUBEN MAMANI',
    //   description:'A description',
    //   account:"40-0-290",
    //   state:'FALSE',
    //   addingDate:'23 Apr 2020',
    // },
    // {
    //   id: 4087,
    //   client:'RAUL RODRIGUEZ',
    //   description:'Another description',
    //   account:"40-0-087",
    //   state:'TRUE',
    //   addingDate:'24 Apr 2021',
    // },
    // {
    //   id: 4090,
    //   client:'RUBEN MAMANI',
    //   description:'A description',
    //   account:"40-0-090",
    //   state:'FALSE',
    //   addingDate:'23 Apr 2022',
    // },
    // {
    //   id: 4887,
    //   client:'RAUL RODRIGUEZ',
    //   description:'Another description',
    //   account:"40-0-887",
    //   state:'TRUE',
    //   addingDate:'20 Apr 2022',
    // },
    // {
    //   id: 4980,
    //   client:'RUBEN MAMANI',
    //   description:'A description',
    //   account:"40-0-980",
    //   state:'FALSE',
    //   addingDate:'23 Apr 2018',
    // },
    // {
    //   id: 4977,
    //   client:'RAUL RODRIGUEZ',
    //   description:'Another description',
    //   account:"40-0-977",
    //   state:'TRUE',
    //   addingDate:'24 Apr 2017',
    // },
    // {
    //   id: 4960,
    //   client:'RUBEN MAMANI',
    //   description:'A description',
    //   account:"40-0-960",
    //   state:'FALSE',
    //   addingDate:'23 Apr 2015',
    // },
    // {
    //   id: 4957,
    //   client:'RAUL RODRIGUEZ',
    //   description:'Another description',
    //   account:"40-0-957",
    //   state:'TRUE',
    //   addingDate:'24 Apr 2014',
    // },
    // {
    //   id: 4490,
    //   client:'RUBEN MAMANI',
    //   description:'A description',
    //   account:"40-0-490",
    //   state:'FALSE',
    //   addingDate:'23 Apr 2013',
    // },
    // {
    //   id: 4387,
    //   client:'RAUL RODRIGUEZ',
    //   description:'Another description',
    //   account:"40-0-387",
    //   state:'TRUE',
    //   addingDate:'24 Apr 2012',
    // },
    // {
    //   id: 4991,
    //   client:'RUBEN MAMANI',
    //   description:'A description',
    //   account:"40-0-991",
    //   state:'FALSE',
    //   addingDate:'15 Apr 2019',
    // },
    // {
    //   id: 5987,
    //   client:'RAUL RODRIGUEZ',
    //   description:'Another description',
    //   account:"50-0-987",
    //   state:'TRUE',
    //   addingDate:'14 Apr 2020',
    // },
    // {
    //   id: 5590,
    //   client:'RUBEN MAMANI',
    //   description:'A description',
    //   account:"50-0-590",
    //   state:'FALSE',
    //   addingDate:'11 Apr 2010',
    // },
    // {
    //   id: 5557,
    //   client:'RAUL RODRIGUEZ',
    //   description:'Another description',
    //   account:"50-0-557",
    //   state:'TRUE',
    //   addingDate:'10 Apr 2021',
    // },
    // {
    //   id: 6987,
    //   client:'LUIS RODRIGUEZ',
    //   description:'Another description',
    //   account:"60-0-987",
    //   state:'TRUE',
    //   addingDate:'14 Apr 2009',
    // },
    // {
    //   id: 6590,
    //   client:'LUIS MAMANI',
    //   description:'A description',
    //   account:"60-0-590",
    //   state:'FALSE',
    //   addingDate:'11 Apr 2009',
    // },
    // {
    //   id: 6557,
    //   client:'LUIS RODRIGUEZ',
    //   description:'Another description',
    //   account:"60-0-557",
    //   state:'TRUE',
    //   addingDate:'10 Apr 2009',
    // }