// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getRoles = createAsyncThunk('roles/getRoles', async params => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/auth/roles?page=1&itemsPage=10`, params)
  return {
    params,
    data: response.data.list,
  }
})

export const getSpecificRole = createAsyncThunk('roleManagmente/getSpecificRole', async id => {
  return await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/auth/role/${id}`)
})

export const resetSpecificRole = createAsyncThunk('roleManagmente/resetSpecificRole', async params => {
  return {
    params,
    data: {},
  }
})

export const getAbilities = createAsyncThunk('abilities/getAbilities', async params => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/auth/abilities?page=1&itemsPage=10`, params)
  return {
    params,
    data: response.data,
  }
})

export const putRole = createAsyncThunk('roleManagmente/putRole', async params => {
  const response = await axios.put(`${process.env.REACT_APP_ENV_BASE_URL}/auth/role`, params)
  return response
})

export const postRole = createAsyncThunk('roleManagmente/postRole', async params => {
  const response = await axios.post(`${process.env.REACT_APP_ENV_BASE_URL}/auth/role`, params)
  return response
})

export const disableRole = createAsyncThunk('roleManagmente/disableRole', async id => {
  const response = await axios.delete(`${process.env.REACT_APP_ENV_BASE_URL}/auth/role/${id}`)
  return response
})

export const roleManagment = createSlice({
  name: 'role',
  initialState: {
    items: [],
    role:{},
    abilities:{}
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getRoles.fulfilled, (state, action) => {
      state.items = action.payload.data
    })
    .addCase(getSpecificRole.fulfilled, (state, action) => {
      state.role = action.payload.data
    })
    .addCase(resetSpecificRole.fulfilled, (state, action) => {
      state.role = action.payload.data
    })
    .addCase(getAbilities.fulfilled, (state, action) => {
      state.abilities = action.payload.data
    })
  }
})

export default roleManagment.reducer