// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getUsers = createAsyncThunk('userManagmente/getUsers', async params => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/auth/users`, params)
  return {
    params,
    data: response.data.users,
  }
})

export const getSpecificUser = createAsyncThunk('userManagmente/getSpecificUser', async uuid => {
  return await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/auth/user/${uuid}`)
})

export const resetSpecificUser = createAsyncThunk('userManagmente/resetSpecificUser', async params => {
  return {
    params,
    data: {},
  }
})

export const getRoles = createAsyncThunk('roles/getRoles', async params => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/auth/roles?page=1&itemsPage=10`, params)
  return {
    params,
    data: response.data,
  }
})

export const putUser = createAsyncThunk('userManagmente/putUser', async params => {
  const response = await axios.put(`${process.env.REACT_APP_ENV_BASE_URL}/auth/user`, params)
  return response
})

export const postUser = createAsyncThunk('userManagmente/postUser', async params => {
  const response = await axios.post(`${process.env.REACT_APP_ENV_BASE_URL}/auth/user`, params)
  return response
})

export const deleteUser = createAsyncThunk('userManagmente/deleteUser', async params => {
  const response = await axios.delete(`${process.env.REACT_APP_ENV_BASE_URL}/auth/user`,{data:params})
  return response
})

// export const disableUser = createAsyncThunk('userManagmente/disableUser', async uuid => {
//   const response = await axios.delete(`${process.env.REACT_APP_ENV_BASE_URL}/auth/user/${uuid}`)
//   return response
// })

export const userManagment = createSlice({
  name: 'user',
  initialState: {
    items: [],
    user: {},
    usersLoading: false,
    users: [],
    roles: {},
    userCreationLoading: false,
    userCreated: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUsers.pending, (state, action) => {
        state.usersLoading = true
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.data
        state.usersLoading = false
      })
      .addCase(getSpecificUser.fulfilled, (state, action) => {
        state.user = action.payload.data
      })
      .addCase(resetSpecificUser.fulfilled, (state, action) => {
        state.user = action.payload.data
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload.data
      })
      .addCase(postUser.pending, (state, action) => {
        state.userCreationLoading = true
      })
      .addCase(postUser.fulfilled, (state, action) => {
        state.userCreationLoading = false
        state.userCreated = action.payload.data?.created ? true : 'error'
      })
  }
})

export default userManagment.reducer