import mock from './mock'

import './jwt'
import './pages/faq'
import './apps/chat'
import './apps/todo'
import './apps/email'
import './apps/invoice'
import './apps/society-addresses'
import './apps/society-inscriptions'
import './apps/shareholding-composition'
import './apps/final-beneficiaries'
import './apps/actived-clients'
import './funds/fund'
import './stock-exchange/cedear'
import './stock-exchange/equity'
import './stock-exchange/bond'
import './stock-exchange/pack'
import './apps/calendar'
import './apps/userList'
import './pages/profile'
import './apps/eCommerce'
import './pages/blog-data'
import './tables/datatables'
import './pages/pricing-data'
import './navbar/navbarSearch'
import './pages/knowledge-base'
import './apps/permissionsList'
import './cards/card-analytics'
import './cards/card-statistics'
import './pages/account-settings'
import './autoComplete/autoComplete'
import './clients/onboarding'
import './funds/recomended-wallet'
import './saving-module/saving-reasons'
import './saving-module/client-goals'
import './clients/onboarding-user-loaded-information'

mock.onAny().passThrough()
