// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getGoal = createAsyncThunk('savingModule/getGoal', async params => {
  const response = await axios.get('/client/goals', params)
  return {
    params,
    data: response.data.goals,
    allData: response.data.allData,
    totalPages: response.data.total
  }
})

// export const deleteSpecie = createAsyncThunk('fund/deleteSpecie', async (id, { dispatch, getState }) => {
//   await axios.delete('/funds/delete', { id })
//   //await dispatch(getAddresses(getState().goal.params))
//   return id
// })

export const savingModuleSlice = createSlice({
  name: 'goals',
  initialState: {
    items: [],
    allGoalData: [],
    totalGoal: 1,
    goalParams: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getGoal.fulfilled, (state, action) => {
      state.items = action.payload.data
      state.allGoalData = action.payload.allData
      state.totalGoal = action.payload.totalPages
      state.goalParams = action.payload.params
    })
  }
})

export default savingModuleSlice.reducer