// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getEquities = createAsyncThunk('equity/getEquities', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/equities`, {
      params: { page: params.page, itemsPage: params.itemsPerPage }
    });
    return {
      params,
      data: response.data,
      totalRecords: response.data.numberRecordsTotal,
      totalPages: response.data.numberOfPages,
      nextPage: response.data.nextPage,
      previousPage: response.data.previousPage 
    };
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

export const getSpecificEquity = createAsyncThunk('equity/getSpecificEquity', async (ticker, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/equity/${ticker}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export const resetSpecificEquity = createAsyncThunk('equity/resetSpecificEquity', async params => {
  return {
    params,
    data: {},
  }
})

export const getTags = createAsyncThunk('tags/getTags', async params => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/markettags?page=1&itemsPage=10`, params)
  return {
    params,
    data: response.data.list,
  }
})

export const getIndices = createAsyncThunk('tags/getIndices', async params => {
  const response = await axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/equityindices?page=1&itemsPage=10`, params)
  return {
    params,
    data: response.data.list,
  }
})

export const putEquity = createAsyncThunk('equity/putEquity', async params => {
  const response = await axios.put(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/equity`, params)
  return response
})

export const disableEquity = createAsyncThunk('equity/disableEquity', async ticker => {
  const response = await axios.delete(`${process.env.REACT_APP_ENV_BASE_URL}/backoffice/equity/${ticker}`)
  return response
})

export const equitySlice = createSlice({
  name: 'equities',
  initialState: {
    items: [],
    totalPages: 1,
    currentPage: 1,
    equitiesParams: {},
    equity:{},
    tags:[],
    indices:[],
    loadingEquities: false,
    loadingSpecificEquity: false,
    loadingTags: false,
    loadingIndices: false,
    errorEquities: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getEquities.pending, state => {
      state.loadingEquities = true;
      state.errorEquities = null;
    })
    .addCase(getEquities.fulfilled, (state, action) => {
      state.items = action.payload.data.list;
      state.totalRecords = action.payload.data.numberRecordsTotal;
      state.totalPages = action.payload.data.numberOfPages;
      state.currentPage = action.payload.params.page;
      state.equitiesParams = action.payload.params;
      state.loadingEquities = false;
    })
    .addCase(getEquities.rejected, (state, action) => {
      state.loadingEquities = false;
      state.errorEquities = action.payload;
    })
    .addCase(getSpecificEquity.pending, state => {
      state.loadingSpecificEquity = true;
      state.errorEquities = null;
    })
    .addCase(getSpecificEquity.fulfilled, (state, action) => {
      state.equity = action.payload;
      state.loadingSpecificEquity = false;
    })
    .addCase(getSpecificEquity.rejected, (state, action) => {
      state.errorEquities = action.payload;
      state.loadingSpecificEquity = false;
    })
    .addCase(resetSpecificEquity.pending, state => {
      state.loadingSpecificEquity = true;
      state.errorEquities = null;
    })
    .addCase(resetSpecificEquity.fulfilled, state => {
      state.equity = {};
      state.loadingSpecificEquity = false;
    })
    .addCase(resetSpecificEquity.rejected, (state, action) => {
      state.errorEquities = action.payload;
      state.loadingSpecificEquity = false;
    })
    .addCase(getTags.pending, state => {
      state.loadingTags = true;
      state.errorEquities = null;
    })
    .addCase(getTags.fulfilled, (state, action) => {
      state.tags = action.payload.data;
      state.loadingTags = false;
    })
    .addCase(getTags.rejected, (state, action) => {
      state.errorEquities = action.payload;
      state.loadingTags = false;
    })
    .addCase(getIndices.pending, state => {
      state.loadingIndices = true;
      state.errorEquities = null;
    })
    .addCase(getIndices.fulfilled, (state, action) => {
      state.indices = action.payload;
      state.loadingIndices = false;
    })
    .addCase(getIndices.rejected, (state, action) => {
      state.errorEquities = action.payload.data;
      state.loadingIndices = false;
    })
  }
})

export default equitySlice.reducer