import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  packs: [
    {
      id: 1,
      name:"Pack Gaming",
      currency:"PESOS",
      risk:"AGRESIVO",
      enabled:true,
      description:"A description 1",
      performance: "5",
      urlImage:"http://una/url/para/el/logo/1",
      specieList: []
    },
    {
      id: 2,
      name:"Pack Tecnológico",
      currency:"DOLAR",
      risk:"MODERADO",
      enabled:false,
      description:"A description 2",
      performance: "75",
      urlImage:"http://una/url/para/el/logo/2",
      specieList: [
        {ticker:"AAPLD", name:"Apple Inc Dolar", percentage:25},
        {ticker:"ALUA", name:"Aluar Alumninio Argentino SAIC", percentage:51},
        {ticker:"AAPL", name:"Apple Inc", percentage:24},
      ]
    }
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/pack/packs').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.packs.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter;
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.packs,
      total: filteredData.length,
      packs: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/packs\/pack\/\d+/).reply(config => {
  // // Get event id from URL
  // const specieId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))
  const specificId = Number(config.id)
  // const specieIndex = data.packs.findIndex(e => e.id === specieId)
  const specieIndex = data.packs.findIndex(e => e.id === specificId)
  const responseData = {
    ...data.packs[specieIndex]
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete(/\/packs\/pack\/delete\/\d+/).reply(config => {
  // Get specie id from URL
  const specieId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))
  // let specieId = config.id
  
  const specieIndex = data.packs.findIndex(t => t.id === specieId)
  data.packs.splice(specieIndex, 1)
  return [200,data.packs]
})