import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  species: [
    {
      id: 1,
      ticker:"AAPL",
      name:"Apple Inc",
      industry:"Tecnología",
      type: "CEDEARs compañias",
      currency: 'ARS',
      operation:'AMBOS',
      lite:true,
      pro:false,
      enabled:true,
      iconURL:"http://URL/del/Icono/para/el/Certificate/AAPL",
      lastModification: '2021-04-07 20:08:23'
    },
    {
      id: 2,
      ticker:"AAPLD",
      name:"Apple Inc Dolar",
      industry:"Tecnología",
      type: "CEDEARs compañias",
      currency: 'USD',
      operation:'AMBOS',
      lite:true,
      pro:true,
      enabled:true,
      iconURL:"http://URL/del/Icono/para/el/Certificate/AAPLD",
      lastModification: '2021-04-07 20:08:23'
    }
  ]
}

// ------------------------------------------------
// GET: Return Certificates List
// ------------------------------------------------
mock.onGet('/cedears/species').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.species.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter;
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.species,
      total: filteredData.length,
      species: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Certificate
// ------------------------------------------------
mock.onGet(/\/api\/cedears\/\d+/).reply(config => {
  // // Get id from URL
  const specieId = Number(config.url.substring(config.url.lastIndexOf('/') + 1));
  const specieIndex = data.species.findIndex(e => e.id === specieId);

  return [200, data.species[specieIndex]]
})